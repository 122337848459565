import React, { useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const KeyResultModal = ({
  owners,
  id,
  getData,
  item,
  detail,
  getOKRListing, 
}) => {
  const [childKeyResult, setchildKeyResult] = useState("");
  const [OKRFilter, setOKRFilter] = useState("keyResult");
  const [keyResult, setkeyResult] = useState({
    title: "",
    unit: "",
    targetNumber: "",
    initialNumber: "",
    description: "",
    ownerIds: [],
    deadline: "no custom deadline",
    softDeadline: "",
    hardDeadline: "",
  });
  const [editor, seteditor] = useState({ keyResult: "", noteKeyResult: "" });

  const clearKeyResultModal = () => {
    setkeyResult({
      title: "",
      unit: "not set",
      targetNumber: "",
      initialNumber: "",
      description: "",
      ownerIds: [],
      deadline: "no custom deadline",
      softDeadline: "",
      hardDeadline: "",
    });
  };

  const handleCreateKeyResult = (e) => {
    e.preventDefault();
    let payload = {
      ...keyResult,
      description: editor?.keyResult,
      ownerIds: keyResult?.ownerIds.map((item) => item?.value),
      okrId: detail?.id || detail?._id,
    };
    payload.targetNumber = Number(payload?.targetNumber);
    payload.initialNumber = Number(payload?.initialNumber);
    if (keyResult?._id && !childKeyResult) {
      payload = { ...payload, id: keyResult?._id };
      ApiClient.put(`okr`, payload).then((res) => {
        if (res.success) {
          document.getElementById("CloseKeyResultModal1").click();
          getData();
          setOKRFilter("keyResult");
        }
      });
    } else {
      payload = {
        ...payload,
        keyType: "key_result",
        parentId: childKeyResult || detail?.id || detail?._id || null,
        keyType: "key_result",
      };
      ApiClient.post(`okr`, payload).then((res) => {
        if (res.success) {
          document.getElementById("CloseKeyResultModal1").click();
          getData();
          setOKRFilter("keyResult");
          getOKRListing();
          toast("New Key Result added! Let's measure success together! 📊", {
            position: "bottom-right",
            autoClose: 4000,
          });
        }
      });
    }
  };
  return (
    <>
      <button
        className="btn key-btn d-none"
        id={`OpenKeyResultModal1234${id}`}
        onClick={(e) => setchildKeyResult("")}
        data-toggle="modal"
        data-target={`#openKeyResultModal${id}`}
      >
        {/* <i className="fa fa-plus me-2"></i> */}
        Add Key Result
      </button>

      <div
        className="modal fade create-key-result"
        id={`openKeyResultModal${id}`}
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="KeyResultModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-title">
              <div className="p-3 d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="modal-title" id="KeyResultModalLabel">
                    {keyResult?._id ? "Edit" : "Create"} Key Result
                  </h3>
                  <p className="mb-1">
                    All required fields are marked with an asterisks (
                    <span className="text-danger">*</span>).
                  </p>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={(e) => clearKeyResultModal()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <form>
              <div className="modal-body">
                <div className="">
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <label>
                        Title <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="text"
                        value={keyResult?.title}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            title: e.target.value,
                          })
                        }
                        maxLength="70"
                        className="form-control"
                        placeholder="Enter Objective title"
                        required
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Description</label>
                    </div>
                    <div
                      className="col-md-9 my-2"
                      name="description"
                      valuePropName="data"
                      getValueFromEvent={(event, editor) => {
                        const data = editor.getData();
                        return data;
                      }}
                    >
                      <CKEditor
                      data={editor?.keyResult}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          seteditor({ ...editor, keyResult: data });
                        }}
                      />
                    </div>

                    <div className="col-md-3 my-2">
                      <label>Unit</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <select
                        className="form-select"
                        value={keyResult?.unit}
                        onChange={(e) =>
                          setkeyResult({ ...keyResult, unit: e.target.value })
                        }
                      >
                        <option value="">Unit</option>
                        <option value="%">Percentage (%)</option>
                        <option value="$">US Dollar ($)</option>
                        <option value="€">Euro (€)</option>
                      </select>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>
                        Target number <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="number"
                        value={keyResult?.targetNumber}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            targetNumber: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Enter target number"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>
                        Initial number <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-md-9 my-2">
                      <input
                        type="number"
                        value={keyResult?.initialNumber}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            initialNumber: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Enter initial number"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Owner</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <div className="multi-select-box">
                        <Select
                          value={keyResult?.ownerIds}
                          isClearable={true}
                          onChange={(e) =>
                            setkeyResult({ ...keyResult, ownerIds: e })
                          }
                          options={owners}
                          className="basic-multi-select"
                          placeholder="Select Owners"
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Deadline</label>
                    </div>
                    <div className="col-md-9 my-2">
                      <select
                        className="form-select"
                        value={keyResult?.deadline}
                        onChange={(e) =>
                          setkeyResult({
                            ...keyResult,
                            deadline: e.target.value,
                          })
                        }
                      >
                        <option value="no custom deadline">
                          No Custom Deadline
                        </option>
                        <option value="hard deadline">Hard Deadline</option>
                        <option value="hard and soft deadline">
                          Hard & Soft Deadline
                        </option>
                      </select>
                    </div>
                    {keyResult?.deadline != "no custom deadline" ? (
                      <>
                        {keyResult?.deadline == "hard and soft deadline" ? (
                          <>
                            <div className="col-md-3 my-2">
                              <label>Soft deadline</label>
                            </div>
                            <div className="col-md-9 my-2">
                              <DatePicker
                                selected={keyResult?.softDeadline}
                                onChange={(date) =>
                                  setkeyResult({
                                    ...keyResult,
                                    softDeadline: date,
                                  })
                                }
                                className="form-control w-100"
                                dateFormat="dd/MMM/yyyy"
                                placeholderText="Soft deadline"
                                minDate={new Date()}
                              />
                            </div>
                          </>
                        ) : null}
                        <div className="col-md-3 my-2">
                          <label>Hard deadline</label>
                        </div>
                        <div className="col-md-9 my-2">
                          <DatePicker
                            selected={keyResult?.hardDeadline}
                            onChange={(date) =>
                              setkeyResult({
                                ...keyResult,
                                hardDeadline: date,
                              })
                            }
                            className="form-control w-100"
                            dateFormat="dd/MMM/yyyy"
                            placeholderText="Hard deadline"
                            minDate={keyResult?.softDeadline || new Date()}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="">
                  <button
                    type="button"
                    disabled={!keyResult?.title || !keyResult?.targetNumber}
                    className="btn btn-primary btn-md mr-2"
                    onClick={(e) => handleCreateKeyResult(e)}
                  >
                    {keyResult?._id ? "Update" : "Add"} Key Result
                  </button>
                  <button
                    type="button"
                    id="CloseKeyResultModal1"
                    onClick={(e) => clearKeyResultModal()}
                    className="btn btn-secondary ml-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyResultModal;
