import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";

const OTPpage = () => {
    const dispatch = useDispatch();
    const email = localStorage.getItem('SignUpEmail')
    const id = localStorage.getItem('SignUpId')
    const [otp, setotp] = useState()
    const history = useHistory()

    const handleOTP = (e) => {
        e.preventDefault()
        ApiClient.post(`api/user/verify-email`, { id: id, otp: otp }).then(res => {
            if (res.success) {
                setTimeout(() => {
                    toast.success(res.message)
                }, 100);
                ApiClient.post(`api/auto/login`, { id: id }).then(res => {
                    if (res.success) {
                        localStorage.setItem("token", res.data.access_token);
                        dispatch(login_success(res.data));
                        localStorage.removeItem('SignUpEmail')
                        localStorage.removeItem('SignUpId')
                        history.push(`/company`)
                    }
                })
            }
        })
    }

    const resendCode = () => {
        ApiClient.post(`api/resend-otp`, { id: id }).then(res => {
            if (res.success) {
                toast.success(res.message)
            }
        })
    }

    return (
        <>
            <div className="">
                <div className="row align-items-center mx-0">
                    <div className="col-md-4 px-0">
                        <div className="banner_img back_img">
                        </div>
                    </div>
                    <div className="col-md-8 p-0">
                        <div className="right_side">
                            <form className="centerLogin" onSubmit={handleOTP}>
                                <div className="login_img">
                                    <img src="/assets/img/logo.png" className="logos" />
                                </div>
                                <div className="text_logo">
                                    <h3>
                                        Integrated Business Intelligence and Sustainability Dashboard
                                        for Tour Operators{" "}
                                    </h3>
                                </div>
                                <div className="mb-4">
                                    <h3 className="lgtext">We just emailed you.</h3>
                                    <p>Please enter the code we emailed you.<br />{email}</p>
                                </div>
                                <div>
                                    <label>Enter verification code</label>
                                    <input type='text' className="form-control" value={otp} onChange={e => setotp(e.target.value)} placeholder="Please enter verification code" required />
                                </div>
                                <div className="m-auto w-100">
                                    <button className="btn btn-primary w-100 mt-4 mb-2">Next</button>
                                    <a onClick={e => resendCode()}>Resend verification code</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </>
    )
}
export default OTPpage