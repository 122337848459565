import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './style.scss';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Forgotpassword = () => {
    const user = useSelector(state => state.user)
    const [form, setForm] = useState({ email: '' });
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/home')
        }
    }, [])

    const hendleSubmit = (e) => {
        e.preventDefault();
        loader(true)
        ApiClient.post('forgot/password/frontend', { ...form, }).then(res => {
            if (res.success) {
                setTimeout(() => {
                    toast.success('Please check your email to reset your password')
                  }, 100);
                
                // ?message=' + res.message
                history.push('/login');
                
            }
            loader(false)
        })
    };

    return (
        <>
        <div className='signIn-main'>
        <div className="container">
                <div className="row ">                  
                    <div className="col-md-6 mx-auto">
                        <div className='right_side'>
                            <form className="centerLogin" onSubmit={hendleSubmit} >
                                <div className="logo_set mb-4" onClick={() => history.push("/home")}>
                                    <img className="logo_login" src="assets/img/images/footer_logo.png" />
                                </div>

                                <div className="text-center mb-3">
                                    <h3 className="text-center lgtext">Forgot password?</h3>
                                </div>
                                <p className='para_forget mb-3 text-center  '>You will receive a link to reset your password</p>
                                <div className="mb-3">
                                    <label>Email*</label>
                                    <div className="inputWrapper">
                                        <input
                                            type="email"
                                            className="form-control  mb-0 bginput" placeholder='Email*'
                                            value={form.email}
                                            required
                                            onChange={e => setForm({ ...form, email: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button type="submit" className="btn btn-primary loginclass mt-3">
                                        Send Recovery Email
                                    </button>
                                </div>
                                <p className='accopuntt'> <Link class="sign_up" to="/login"> <i class="fa fa-arrow-left me-2" aria-hidden="true"></i>
Go Back to Log in</Link></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </div>
         
            
        </>
    );
};

export default Forgotpassword;
