import React, { useState } from 'react';
import './style.scss';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Html from './Html';
import permissionModel from "../../../models/permisstion.model"

const Sidebar = ({ isOpen, setIsOpen ,resources}) => {
  const user = useSelector(state => state.user)
  const history = useHistory()
  const menus = {
    users: ['roles', 'users'],
    sales: ['sales', 'saleData'],
    catalogue: ['types', 'categories', 'category/Reseller'],
    productdashboard: ['productdashboard', 'products', 'productData', 'product/detail'],
    dynamicPricing: ['dynamicprice'],
    reviews: ['reviews', 'reviewDashboard'],
    plan: ['plans', 'card'],
    customer: ['customer'],
    marketing: ['marketing'],
    financial: ['financial', 'xero'],
    settings: ['settings', 'company', 'activeplan', 'plans', 'card', 'users', 'roles', 'product', 'pos', 'api/', 'marketing-analytics', 'reviewStep', 'roles', 'users'],
    crm: ['crm', 'reseller', 'databaseReseller'],
    xero: ['xero'],
    api: ['product', 'pos', 'api/', 'marketing-analytics', 'reviewStep', 'review-connected'],
    geo: ['continents', 'countries', 'regions', 'cities'],
  }

  const ListItemLink = ({ to, type = 'link', disabled = false, ...rest }) => {
    let url = location.href
    const host = location.host
    url = url.split(host)[1]
    return (<>
      {type == 'link' ? <li className={`nav-item ${url.includes(to) ? 'active' : ''}`}>
        {/* {...rest} */}
        <Link to={to} {...rest} className={`nav-link hoverclass ${disabled ? 'disabled' : ''}`} disabled={disabled} />
      </li> : <li className={`nav-item main ${url.includes(to) ? 'active' : ''}`} {...rest}></li>}
    </>
    );
  };

  const matchUrl = (to, exact = false) => {
    let url = location.href
    const host = location.host
    url = url.split(host)[1]
    if (exact) return url == to
    if (!exact) return url.includes(to)
  }

  const tabclass = (tab) => {
    let url = location.href
    let value = false
    menus[tab].map(itm => {
      if (url.includes(itm)) value = true
    })
    return value
  }

  const urlAllow = (url) => {
    let permissions = user.subRole?.permissions[0]
    let arr = url.split(',')
    let value = false
    arr.map(itm => {
      if (permissionModel.urlAllow(permissions, itm)) value = true
    })
    if (user.subRole) {
      return value
    }
    else {
      return true
    }
  }

  const route = (p) => {
    history.push(p)
  }

  return <>
    <Html
      route={route}
      tabclass={tabclass}
      ListItemLink={ListItemLink}
      urlAllow={urlAllow}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      resources={resources}
    />
  </>
};

export default Sidebar;
