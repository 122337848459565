import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { RiBillLine } from "react-icons/ri";
import { SlLocationPin } from "react-icons/sl";
import { RiFileHistoryLine } from "react-icons/ri";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";


import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { move } from "formik";
import { useDispatch } from "react-redux";






const Billings = () => {
  const Navigate = useHistory();

  const user = useSelector((state) => state.user)
  const [data, setData] = useState();
  const [tab, settab] = useState([]);
  const dispatch = useDispatch();


  const navigate = useState()

  const table = () => {
    ApiClient.get(`users/list?addedBy=${user?.id || user?._id}`).then((res) => {
      if (res?.data) {
        // setData(res?.data)
        settab(res?.data)
      }
    })

  }

const move = () =>
{
  Navigate.push("/plan");

}
  const getTotalUser = (p = {}) => {
    loader(true)
    ApiClient.get(`profile?id=${user?.id}`).then((res) => {
      if (res.success) {
        setData(res?.data)
      }
      loader(false)
    });
  };

  useEffect(() => {
    getTotalUser()
    table()
    getTotalUser()
  }, [])


  const edit = ()=>
  {

    ApiClient.get(`users/list?addedBy=${user?.id || user?._id}`).then((res) => {
    })

    // ApiClient.getPut(`edit/profile?id=`)
  }


  return (
    <>
      <Layout>
        <div className="inners_wrapper">
          <div className="row">
            <div className="col-md-8">
              <div className="billing-page-main">
                <div className="billing-page-head">
                  <h2>Billing & Plan</h2>
                  <ul>
                    <li>
                      <a
                        data-toggle="modal"
                        data-target="#exampleModal"
                        className="cursor-pointer"
                      >
                        <RiBillLine />
                        Subscription Details
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="modal"
                        data-target="#shippingModal"
                        className="cursor-pointer"
                      >
                        <SlLocationPin />
                        Billing & shipping address
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="modal"
                        data-target="#billingHistoryModal"
                        className="cursor-pointer"
                      >
                        <RiFileHistoryLine />
                        Billing history
                      </a>
                    </li>
                  </ul>
                  
                </div>


               





                <div className="billing-plan mt-5">
                  <h5 className="small-txt">Your plan</h5>
                  <div className="plan-section-detail">
                    <div className="essentials-text">
                      <h2 className="mb-2">Essentials</h2>
                      <p className="small-txt mb-4">
                        You’re currently on our free plan.
                      </p>
                      <p className="small-txt mb-4">
                        Upgrade to use Insightboards, KPIs, Reports and more.
                      </p>
                      <p className="small-txt">
                        Cancel your subscription by contacting us directly via
                        the chat button in the bottom right of your screen.
                      </p>
                    </div>
                    <div className="upgrade-btns">
                      <button className="btn btn-primary" onClick={move} >Upgrade Plan</button>
                      <button className="btn btn-secondary">Contact us</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Subscription Modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Subscription Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body p-0">
                <div className="subscription-detail">
                  <h6 className="mb-0">Kanam Results Add-on for Unlimited View-Only Users</h6>
                  <p className="">
                    $0.00<span className="small-txt ms-1">Billed every month</span>
                  </p>
                  <div className="upgrade-btns">
                    <button type="button" class="btn btn-secondary">
                      Update your Shipping Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Shipping address Modal */}
        <div
          class="modal fade"
          id="shippingModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="shippingModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="shippingModalLabel">
                  Update your billing details

                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div className="shipping-address">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input type="text" placeholder="First Name" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input type="text" placeholder="Last Name" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input type="email" placeholder="Email" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Company</label>
                          <input type="email" placeholder="Company" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Address Line 1</label>
                          <input type="text" placeholder="" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Address Line 2</label>
                          <input type="text" placeholder="" className="form-control" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" placeholder="City" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Zip Code</label>
                          <input type="text" placeholder="Zip Code" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>State</label>
                          <input type="text" placeholder="State" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <input type="text" placeholder="Country" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">



                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Blling history Modal */}
        <div
          class="modal fade"
          id="billingHistoryModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="billingHistoryModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="billingHistoryModalLabel">
                  Billing history

                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p className="small-txt mb-0 text-center">No invoices to display</p>
              </div>
              <div class="modal-footer">

                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* 77777777777777777 */}
{/* 
        <div className="mvp_table">
              <div className=" table-responsive">
                <table className="table table-hover mt-3">
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Role</th>
                      {/* <th scope="col">Manager Name</th>
                      <th scope="col">Employee Name</th> */}
                      {/* <th scope="col">Description</th>
                      <th scope="col">Monthly Cost</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  { (
                    <tbody>
                      {tab &&
                        tab.map((item) => {
                          return (
                            <tr>
                              <td>
                                <span className="text-capitalize d-flex align-items-center">
                                  {
                                    item.fullName
                                  }

                                </span>
                              </td>
                              <td>
                              <span className="text-capitalize d-flex align-items-center"/>
                                {
                                    item.role
                                  }
                                   */}
                                  {/* </td> */} 

                                  {/* 7777777777777777777777777 */}
                                
                              {/* <td>
                                <span className="fs-5">
                                  {item?.managerData?.fullName || '--'}
                                </span>
                              </td>

                              <td>
                                <span className="fs-5">
                                  {item?.employeeDetails?.fullName || '--'}
                                </span>
                              </td> */}

                              {/* <td>
                              <span className="text-capitalize d-flex align-items-center"/>
                                {
                                    item.status
                                  }

                              </td>
                              <td>
                              <span className="text-capitalize d-flex align-items-center"/>
                                ${item?.planData?.pricing?.[0]?.unit_amount || '0'}
                              </td>
                              {/*  */}
                              {/* <td>

                                <i className="fa fa-pen me-2" title="Edit" onClick={edit}></i>
                                <i className="fa fa-trash pointer" title="Delete"></i>
                              </td>

                            </tr> */}
                          {/* ); */}
                        {/* })} */}
                    {/* </tbody> */}
                  {/* )} */}
                {/* </table> */} 
              {/* </div> */}
            {/* </div> */}
  



      </Layout>
    </>
  );
};

export default Billings;
