import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Select from "react-select";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";


const EmployeeDetail = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [detail, setdetail] = useState();
  const [managers, setmanagers] = useState();
  const [form, setform] = useState({ edit: false , teamId:''});
  const navigator = useHistory();
  const [teams, setteams] = useState();

  console.log(form,"0000000010010101")

  useEffect(() => {
    if (id) {
      getDetail();
    }
    getManagers();
  }, []);


  // karm code
  useEffect(() => {
    getTeam()
  },[]);

  const getManagers = () => {
    
      ApiClient.get(`users/list?status=active&addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        setmanagers(
          res?.data?.map((item) => {
            return { value: item?.id || item?._id, label: item?.fullName };
          })
        );
      }
    });
  };

// karm code
  const getTeam = () => {
    ApiClient.get(`teams?addedBy=${user?.id}`).then((res) => {
      if (res.success) {
        setteams(
          res?.data?.map((item) => {
            return { value: item?.id || item?._id, id: item?.id || item?._id, label: item?.fullName||"",name:item?.fullName ,managerName:item?.managerEmail};
            // **********************
          })
        );
      }
    });
  };
  // =====
  const getDetail = () => {
    loader(true);
    ApiClient.get(`user/details?id=${id}`).then((res) => {
      if (res.success) {
        setdetail(res?.data);
        console.log(res?.data,"--------rrrrrrrrrrrrr")
        setform({
          fullName: res?.data?.fullName,
          email: res?.data?.email,
          // managerId: {
          //   value: res?.data?.managerId?.id,
          //   label: res?.data?.managerId?.fullName,
          // },
          managerId:res?.data?.managerAndTeam?.map((item) => {
            return { value: item?.id, label: item?.fullName ,email:item?.email};
          }),
          teamId:res?.data?.managerAndTeam?.map((item) => {
            return {id: item?.team?.id, value: item?.team?.id, label: item?.team?.fullName , managerName:item?.fullName };
          }),
          image: res?.data?.image,
        });
      }
      loader(false);
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let payload = {
      id: detail?.id,
      fullName: form?.fullName,
      email: form?.email,
      // manager: form?.managerId,
      teamId:form?.teamId?.map((item) => {
        return item?.id;
      }),
      image: form?.image,
    };
    ApiClient.put(`edit/profile`, payload).then((res) => {
      if (res.success) {
        getDetail();
        toast.success("Employee Updated Successfully");
        setform({ edit: false });
      }
    });
  };

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this employee")) {
      ApiClient.delete(`user/delete?id=${id}`).then((res) => {
        if (res.success) {
          setTimeout(() => {
            toast.success(res.message);
          }, 100);
          navigator.push(`/employees`);
        }
      });
    }
  };

  const handleStatus = (id) => {
    let status = detail?.status == "active" ? "deactive" : "active";
    if (
      window.confirm("Do you really want to change status of this employee")
    ) {
      ApiClient.put(`change/status?model=users&id=${id}&status=${status}`).then(
        (res) => {
          if (res.success) {
            toast.success(res?.message);
            getDetail();
          }
        }
      );
    }
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", {
      file: file,
      modelName: "users",
    }).then((res) => {
      if (res.success) {
        let image = res.data.fullpath;
        setform({ ...form, image: image });
      } else {
        setform({ ...form, image: "" });
      }
      loader(false);
    });
  };

  const handleCancel = () => {
    setform({ edit: false });
    getDetail();
  };

  return (
    <>
      <Layout>
        <div className="inners_wrapper main-view-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <form onSubmit={handleUpdate} className="border">
                <div className="bg-white">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-between head-view">
                      <div className="d-flex align-items-center">
                          <label className="position-relative labeledit edit-profile-view mb-0">
                            <img
                              src={methodModel.userImg(form?.image)}
                              width=""
                              className={form?.edit ? "pointer" : ""}
                            />
                            {form?.edit ? (
                              <>
                                <input
                                  id="bannerImage"
                                  type="file"
                                  className="d-none"
                                  accept="image/*"
                                  value={form.baseImg ? form.baseImg : ""}
                                  onChange={(e) => {
                                    uploadImage(e);
                                  }}
                                />
                                <Tooltip placement="top" title="Upload Image">
                                  <i className="fa fa-pen pointer"></i>
                                </Tooltip>
                              </>
                            ) : null}
                          </label>
                          <span className="ml-3">
                            <div className="d-flex  justify-content-between ">
                              {!form?.edit ? (
                                <>
                                  <h3 className="team-head">{detail?.fullName}</h3>
                                 
                                   
                                 
                                 
                                </>
                              ) : (
                                <div className="input-field-new">
                                <input
                                  type="text"
                                  className="form-control field-change"
                                  value={form?.fullName}
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      fullName: e.target.value,
                                    })
                                  }
                                  required
                                />
                                </div>
                                
                              )}
                            </div>
                            <p className="creation-date">
                              Created on{" "}
                              {moment(detail?.createdAt).format(
                                "MMMM DD, YYYY"
                              )}
                            </p>
                          </span>
                        </div>
                        {user?.role != 'employee' && <div className="dropdowns_custom ">
                                    <div className="dropdown">
                                      <span
                                        className=" text-dark p-2"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-h text-dark"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <div className="dropdown-menu">
                                      <span
                                      className=" dropdown-item pointer"
                                      onClick={(e) =>
                                        setform({ ...form, edit: true })
                                      }
                                    ><i className="fa fa-pen me-2"></i> Edit</span>
                                        <span
                                          className="dropdown-item pointer"
                                          onClick={(e) =>
                                            handleStatus(detail?.id)
                                          }
                                        > <i
                                        class="fa fa-users me-2"
                                        aria-hidden="true"
                                      ></i>
                                          {detail?.status == "active"
                                            ? "Deactivate"
                                            : "Activate"}{" "}
                                          Employee
                                        </span>
                                        <span
                                          className="dropdown-item text-danger pointer"
                                          onClick={(e) =>
                                            handleDelete(detail?.id)
                                          }
                                        ><i className="fa fa-trash me-2"></i>
                                          Delete Employee
                                        </span>
                                      </div>
                                    </div>
                                  </div>}
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="bg-white">
                  <div className="row description-detail team-detail-view">
                    <div className="col-md-3 my-2">
                      <label>Email</label>
                    </div>
                    <div className="col-md-9 my-2">
                      {!form?.edit ? (
                        <p className="mb-0">{detail?.email}</p>
                      ) : (
                        <input
                          type="email"
                          value={form?.email}
                          onChange={(e) =>
                            setform({ ...form, email: e.target.value })
                          }
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      )}
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Manager</label>
                    </div>
                    <div className="col-md-9 my-2">
                      {!form?.edit ? (
                        <p className="mb-0">{detail?.managerAndTeam.map(itm=>(itm.fullName)).join(", ")}</p>
                      ) : (
                        <div className="multiselect-custom multiselect-new">
                            <p className=" border rounded p-1 d-flex flex-wrap">{form?.teamId?.length>0?form?.teamId?.map(itm=>{
                              return <span className="mr-2 my-1 border rounded p-1 ">{itm?.managerName||"--"}</span>
                            }):<></>}</p>
                          </div>
                        // <Select
                        //   value={form?.managerId}
                        //   isClearable={true}
                        //   onChange={(e) =>{ 
                        //     console.log(e,"njkn")
                        //     setform({ ...form, managerId: e })}}
                        //   options={managers}
                        //   placeholder="Select Manager"
                        //   isDisabled={true}
                        // />
                      )}
                    </div>
{/* Karm code */}
                    <div className="col-md-3 my-2">
                      {!form?.edit ? <label>
                        Team
                      </label> :
                        <label>
                          Team <span className="text-danger">*</span>
                        </label>
                      }
                    </div>

                    <div className="col-md-9 my-2">
                    {!form?.edit ? (
                        <p className="mb-0">{form?.teamId &&form?.teamId?.map(itm => itm?.label).join(", ")}
                        </p>
                      ) : (
                        <div className="multiselect-custom">
                             <Multiselect
                               avoidHighlightFirstOption={true}
                               selectedValues={form?.teamId}
                               options={teams}
                               displayValue={"label"}
                               onSelect={e => setform({ ...form, teamId: e })}
                               onRemove={e => setform({ ...form, teamId: e })}
                             />
                         </div>)}
                     </div>
{/* -------- */}
                    {/* {!form?.edit ? (
                      <>
                        <div className="col-md-3 my-2">
                          <label>Team</label>
                        </div>
                        <div className="col-md-9 my-2">
                          <p className="mb-0">{detail?.managerAndTeam.map(itm=>(itm.teamName))}</p>
                        </div>
                      </>
                    ) : null} */}
                    {!form?.edit ? (
                      <>
                        <div className="col-md-3 my-2">
                          <label>Status</label>
                        </div>
                        <div className="col-md-9 my-2">
                          <p className="text-capitalize mb-0">
                            {detail?.status=="deactive"?"inactive":detail?.status}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {form?.edit ? (
                      <div className="col-md-12 d-flex justify-content-end mt-4">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={(e) => handleCancel()}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary mx-3">
                          Update
                        </button>
                      </div>
                    ) : null}
                  </div>                      
                  </div>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EmployeeDetail;
