import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import Pagination from "react-pagination-js";
import { Tooltip } from "antd";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import { useSpring, animated } from '@react-spring/web';
import SvgIcon from '@mui/material/SvgIcon';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

const OkrTeam = () => {
    const { id } = useParams();
    const [data, setdata] = useState([]);
    const [filter, setfilter] = useState({ search: "" });
    const [loading, setloading] = useState(false);
    const [total, settotal] = useState(0);
    const [detail, setdetail] = useState();



    // const getData = (p = {}) => {
    //     let filt = { ...filter, ...p, teamId: id };
    //     loader(true);
    //     setloading(true)
    //     ApiClient.get(`okrs`, filt).then((res) => {
    //         if (res.success) {
    //             setdata(res?.data);
    //             settotal(res?.total);
    //             if (detail?.id || detail?._id) {
    //                 setdetail(res?.data?.find(item => item?._id == detail?.id || item?._id == detail?._id))
    //             }
    //         }
    //         setloading(false)
    //         loader(false);
    //     }); 
    // };


    const getData = (p = {}) => {
        let filt = { ...filter, ...p };
        loader(true);
        setloading(true)
        ApiClient.get(`okrs`, filt).then((res) => {
          if (res.success) {
            setdata(res?.data);
            settotal(res?.total);
            // if (detail?.id || detail?._id) {
            //   setdetail(res?.data?.find(item => item?._id == detail?.id || item?._id == detail?._id))
            // }
          }
          setloading(false)
          loader(false);
        });
      };
    

    useEffect(() => {
        // if (id) {
            getData({teamId:id});
        // }
    }, [id]);


    const pageChange = (e) => {
        setfilter({ ...filter, page: e });
        getData({ page: e });
    };





    const findOKRProgress = (data) => {
        return methodModel.findOKRProgress(data)
    };

    


    // Hierarchy Tree
    function MinusSquare(props) {
        return (
            <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
                {/* tslint:disable-next-line: max-line-length */}
                <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
            </SvgIcon>
        );
    }
    function PlusSquare(props) {
        if (props?.data?._id) {
            return (
                <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
                    {/* tslint:disable-next-line: max-line-length */}
                    <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
                </SvgIcon>
            );
        } else {
            return ''
        }
    }
    function CloseSquare(props) {
        return (
            <SvgIcon
                className="close"
                fontSize="inherit"
                style={{ width: 14, height: 14 }}
                {...props}
            >
                {/* tslint:disable-next-line: max-line-length */}
                <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
            </SvgIcon>
        );
    }
    function TransitionComponent(props) {
        const style = useSpring({
            to: {
                opacity: props.in ? 1 : 0,
                transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
            },
        });

        return (
            <animated.div style={style}>
                <Collapse {...props} />
            </animated.div>
        );
    }
    const CustomTreeItem = React.forwardRef((props, ref) => (
        <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
    ));
    const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }));

    return (
        <>
            <Layout>
                <div className="inners_wrapper ">

                    <>
                        {!loading ? (
                            <div className="mvp_table">
                                <div className=" table-responsive">
                                    <table className="table table-hover mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Owner</th>
                                                <th scope="col">Objective name</th>
                                                <th scope="col">Key Results and Child Objectives</th>
                                                <th scope="col">Progress</th>
                                            </tr>
                                        </thead>
                                        {loading ? null : (
                                            <tbody>
                                                {data &&
                                                    data.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex owners-number">
                                                                        {item?.ownerDetails?.map((itm) => {
                                                                            return (
                                                                                <>
                                                                                    {itm?.id ? (
                                                                                        <Tooltip title={itm?.name} placement="top">
                                                                                            <span className="">
                                                                                                <img src={methodModel.userImg(itm?.image)} width="10px" /></span>
                                                                                        </Tooltip>
                                                                                    ) : (
                                                                                        <span className="fs-6">No Owner</span>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Tooltip title="Objective" placement="top">
                                                                        <span className="fs-5 pointer" >{item?.title}</span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>
                                                                    {item?.keyResult?.length > 0 ?
                                                                 
                                                                        item?.keyResult?.map((itm, index) => {
                                                                            return <>
                                                                                <Box sx={{ minHeight: 10, flexGrow: 1, maxWidth: 400 }}>
                                                                                    <TreeView
                                                                                        aria-label="customized"
                                                                                        defaultExpanded={['0']}
                                                                                        defaultCollapseIcon={<MinusSquare />}
                                                                                        defaultExpandIcon={<PlusSquare data={item} />}
                                                                                        defaultEndIcon={<CloseSquare />}
                                                                                        sx={{ overflowX: 'hidden' }}
                                                                                    >
                                                                                        <StyledTreeItem nodeId={index + 1} label={
                                                                                            <div className="py-3">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-4">
                                                                                                        <label className="text-capitalize mb-0">{itm?.title}</label>
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        {itm?.confidenceLevel ? (
                                                                                                            <span className={`p-2 radius-sm badge-tbl  btn-size-5 ${itm?.confidenceLevel == "low" ? "yello-btn" : itm?.confidenceLevel == "medium" ? "btn-primary" : "btn-success"} text-capitalize`}>{itm?.confidenceLevel}</span>
                                                                                                        ) : null}
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <span className="mb-0">{(itm?.newValue / itm?.targetNumber) * 100}%</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }>
                                                                                            <StyledTreeItem label={`Child`} />

                                                                                        </StyledTreeItem>
                                                                                    </TreeView>
                                                                                </Box>
                                                                            </>
                                                                        })
                                                                        : '--'}
                                                                </td>

                                                                <td className="text-danger">
                                                                    <Tooltip title="Objective Progress" placement="top"><span className="pointer">{findOKRProgress(item?.keyResult)}</span>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>
                        ) : null}
                        {!loading && total == 0 ? (
                            <div className="py-3 text-center no-data"><img src="/assets/img/no-data-logo.svg" />No Data Found</div>
                        ) : (
                            <></>
                        )}
                        {!loading && total > filter.count ? (
                            <div className="paginationWrapper">
                                <span>Show {filter.count} from {total} records</span>
                                <Pagination
                                    currentPage={filter.page}
                                    totalSize={total}
                                    sizePerPage={filter.count}
                                    changeCurrentPage={pageChange}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                </div>
            </Layout >
        </>
    );
};

export default OkrTeam;
