import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import ApiClient from "../../methods/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Swal from "sweetalert2";
import { login_success } from "../../actions/user";
import { Redirect, useHistory } from "react-router-dom";

const BillingDetailModal = ({
  show,
  result=()=>{},
  handleClose,
  handleShow,
  paymentData,
  getData,
  pricing,
}) => {
  const user = useSelector((state) => state.user);
  const [form, setForm] = useState({});
  const [cardList, setCardList] = useState([]);
  const [numOfSeates, setNumOfSeates] = useState(1);
  const [submit, setSubmit] = useState(false);
  const [selectCard, setSelectCard] = useState({});
  const [isCardSelect, setIsCardSelect] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [num, setNum] = useState(paymentData.map(() => 1));
  const history = useHistory();

  useEffect(()=>{
    setNum(paymentData.map(() => (user?.planData?.plan_type=='free'||(user?.planData?.plan_type=='paid'&&!user.planAmountPaid))?user.totalAddedUsers:user?.numberOfSeats|| 1))
  },[user?.numberOfSeats,paymentData.length])

  function getPaidPlanFeatures(plans) {
    const paidPlanFeatures = [];
    plans.forEach(plan => {
      if (plan.plan_type === "paid") {
        let features = plan.features.map(feature => feature.feature_name);
        features=features.filter(itm=>itm?.status=='active'||!itm?.status)
        paidPlanFeatures.push(...features);
      }
    });

    return paidPlanFeatures;
  }
  const paidPlanFeatures = getPaidPlanFeatures(paymentData);

  useEffect(() => {
    if (paymentData?.plan_type == "free") {
      setNumOfSeates(paymentData?.numberOfPeople);
    }
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault(e);
    setSubmit(true);

    if (
      !dateType("card_number", form?.card_number) ||
      !dateType("cvc", form?.cvc) ||
      !dateType("month", form?.exp_month) ||
      !dateType("year", form?.exp_year)
    )
      return;

    let value = {
      ...form,
    };
    loader(true);
    ApiClient.post("card", value).then((res) => {
      if (res.success) {
        // toast.success('Add Card Successfully')
        getAllCard();
        setForm({ card_number: "", cvc: "", exp_month: "", exp_year: "" });
        setSubmit(false);

        let value = {
          planId: paymentData?._id || paymentData?.id,
          planType: paymentData?.plan_type,
          planInterval: pricing?.interval_count,
          stripe_price_id:
            paymentData?.plan_type == "free"
              ? paymentData.stripe_price_id
              : pricing?.stripe_price_id,
          paidAmount:
            paymentData?.plan_type == "free"
              ? 0
              : pricing?.unit_amount * Number(numOfSeates),
          cardId: res?.data?.cardId,
          seats: Number(numOfSeates),
          freePlanBuy: paymentData?.plan_type == "free" ? true : false,
        };
        ApiClient.post("subscription", value).then((res) => {
          if (res.success) {
            setTimeout(() => {
              toast.success(res?.message);
            }, 100);
            handleClose();
            getAllCard();
            gallaryData();
            history.push("/teams");
          }
          loader(false);
        });
      }
    });
  };

  const sortFeature = (data) => {
    let arry = [];
    data.forEach((element) => {
      element.isChecked ? arry.unshift(element) : arry.push(element);
    });

    return arry;
  };

  const dateType = (type, data) => {
    let isTrue = false;

    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();

    let isYear = currentYear <= data;

    let month = moment(currentDate).format("MM");

    let isMonth =
      form?.exp_year > currentYear
        ? data <= "12"
        : month <= data && data <= "12";

    let cvc = data?.length == 3;

    let cardNumber = data?.length == 16;

    const dateType =
      type == "year"
        ? isYear
        : type == "month"
          ? isMonth
          : type == "card_number"
            ? cardNumber
            : cvc;

    if (!data) {
      isTrue = false;
    } else if (dateType) {
      isTrue = true;
    } else {
      isTrue = false;
    }

    return isTrue;
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete("card", { card_id: id }).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getAllCard();
          }
          loader(false);
        });
      }
    });
  };

  const getAllCard = () => {
    loader(true);
    ApiClient.get("card").then((res) => {
      if (res.success) {
        setCardList(res.data);
        res?.data?.length < 1 && setIsAddCard(true);
        if (res?.data?.length == 1) {
          setIsCardSelect(true);
          setSelectCard({ ...res.data[0] });
        }
      }
      loader(false);
    });
  };


  const Stripe = (e, stripId, interCount, numSeat, plnid) => {
    e.preventDefault();
    let url = "update/seats";
    let type = "increase";

    let seats=Number(numSeat||0)

    let payload = {
      id:user.id||user._id,
      seats:Number(seats),
      type:type
    }


    if(user?.planAmountPaid){
      if(seats==user.numberOfSeats){
        toast.error("Plese increase or decrease your seats")
       return
      }
      if(seats>user.numberOfSeats){
        // seats=seats-user.numberOfSeats
      }else{
        // url='decrease/seats'
        type="decrease"
      }
      payload.type=type
    }else{
      url="payment"
      payload = {
        plan_id: plnid || paymentData?.id,
        user_id: user?.id,
        planAmountPaid: user.planAmountPaid,
        stripe_price_id: stripId || paymentData?.pricing[0]?.stripe_price_id,
        interval_count: interCount || paymentData?.pricing[0]?.interval_count,
        seats: Number(seats),
        isTrial: 'no',
      };
    }
    

   

    // if(Number(numSeat||0)<=user.numberOfSeats&&user?.planAmountPaid){
    //   payload={
    //     id:user.id||user._id,
    //     seats: Number(seats),
    //   }
    // }

   
    loader(true);
    ApiClient.post(url, payload).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        handleClose();
        gallaryData();
        result();
        // if((Number(numSeat||0)>user.numberOfSeats)||!user?.planAmountPaid){
        //   window.location.assign(res?.data?.url);
        // }

        if(!user?.planAmountPaid){
          window.location.assign(res?.data?.url);
        }
      }
      loader(false);
    });
  };

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        let u={...user,...res.data}
        dispatch(login_success(u));
      }
      loader(false);
    });
  };

  const handleChange = (e, index) => {
    const newNum = [...num];
    newNum[index] = parseInt(e.target.value);
    setNum(newNum);
  };

  const incNum = (index) => {
    const newNum = [...num];
    newNum[index]++;
    setNum(newNum);
  };

  const decNum = (index) => {
    const newNum = [...num];
    if (newNum[index] > 1) {

      let totalAddedUsers=user?.totalAddedUsers||1
      console.log("totalAddedUsers",totalAddedUsers)
      console.log("num",newNum[index])
      if(newNum[index]<=totalAddedUsers){
        toast.error(`There are ${totalAddedUsers} active employees, to reduce the number of seats, please remove or deactivate some employees`)
        return
      }

      newNum[index]--;
      setNum(newNum);
    }
  };

  const getamountofPlan = (plan, interval) => {
    if (plan) {
      let pricing = [...plan]
      let amount = 0
      pricing?.filter(itm => {
        if (itm?.interval_count == interval) {
          amount = itm?.unit_amount || O
        }
      })
      return Number(amount)
    } else {
      return 0
    }
  }

  const getStriprId_ofPlan = (plan, interval) => {
    if (plan) {
      let pricing = [...plan]
      let stripe_id = ""
      pricing?.filter(itm => {
        if (itm?.interval_count == interval) {
          stripe_id = itm?.stripe_price_id || ""
        }
      })
      return (stripe_id)
    } else {
      return ""
    }
  }

  const isactivePlan=(plan,interval)=>{
    if(plan){
      let pricing=[...plan]
      let value=false
      pricing.map(item=>{
        if(item?.interval_count==interval&&item?.isActiveMonth){
          value=true
        }
      })
      return value
    }
  }

  const cancelPopup=()=>{
    Swal.fire({
      title: "Are you sure you want to cancel your plan ?",
      // text: "Are you sure you want to cancel your plan ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((res) => {
      if (res.isConfirmed) {
        loader(true)
        ApiClient.delete('cancel/subscription').then(res=>{
          loader(false)
          if(res.success){
            setTimeout(() => {
              toast.success(res?.message);
            }, 100);
            handleClose();
            gallaryData();
            result();
          }
        })
      }
    });
  }

  return (
    <>
      <Modal
        className={"modal_width payment-modal"}
        show={show}
        onHide={handleClose}
        dialogClassName="modal-60w"
      >
        <Modal.Header closeButton className="align-items-center">
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="row">
            <div className="col-md-5 mx-auto">
              <ul
                class="nav nav-pills okrs_tabsul mb-4 align-items-center justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    data-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <LuCalendar />
                    Monthlysd
                  </button>
                </li> */}
                {/* <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    data-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <LuCalendar />
                    Quaterly
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-toggle="pill"
                    data-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    <LuCalendar />
                    Yearly
                  </button>
                </li> */}
              {/* </ul>
            </div>
          </div> */}

          <div className="scrollbar-height-set">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="payment-structure py-3">
                  <div className="row">
                    {paymentData.map((item, index) => (
                      <>
                        {item.pricing.length > 0 && (
                          item?.pricing[0]?.interval_count == 1 ? <div className="col-md-4" key={index}>
                            <div className="price-plans">
                              <h4 className="plan-title text-capitalize">{item?.name}</h4>
                              <div className="package-price">
                                <h2>
                                  $
                                  {num[index] *
                                    getamountofPlan(item?.pricing, 1)}
                                </h2>
                                <span class="monthly-txt">/monthly</span>
                              </div>
                              {/* <p className="email-text text-center">1 sending mail</p> */}
                              <div className="range-btns  py-2">
                                <div className="quantity">
                                  <button
                                    className="minus"
                                    aria-label="Decrease"
                                    onClick={() => decNum(index)}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    className="input-box"
                                    value={num[index]}
                                    min="1"
                                    disabled
                                    max="10"
                                    onChange={(e) => handleChange(e, index)}
                                  />
                                  <button
                                    className="plus"
                                    aria-label="Increase"
                                    onClick={() => incNum(index)}
                                  >
                                    +
                                  </button>
                                </div>
                                <div className="users-text">
                                <p>{num <= 1 ? "Seat" : "Seats"}</p>


                                </div>

                              </div>
                              {isactivePlan(item?.pricing,1) ? <p className="ribbon"><img src="assets/img/grey.png" /><span>Current Plan</span></p> : null}
                              <div>
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) =>
                                    Stripe(
                                      e,
                                      getStriprId_ofPlan(item?.pricing, 1),
                                      1,
                                      num[index],
                                      item?._id
                                    )
                                  }
                                >
                                  Update Plan
                                </button>

                                <button
                                        type="button"
                                        class="btn cancel-plan text-center w-100"
                                        onClick={()=>{
                                          cancelPopup()
                                        }}
                                      >
                                        Cancel plan
                                      </button>
                              </div>
                            </div>
                          </div> : <div className=""></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="payment-structure py-3">
                  <div className="row">
                    {paymentData.map((item, index) => (
                      <>
                        {item.pricing.length > 0 && (
                          (item?.pricing[0]?.interval_count == 3 || item?.pricing[1]?.interval_count <= 3) ? <div className="col-md-4" key={index}>
                            <div className="price-plans">
                              <h4 className="plan-title">{item?.name}</h4>
                              <div className="package-price">
                                <h2>
                                  ${" "}
                                  {num[index] *
                                    getamountofPlan(item?.pricing, 3)}
                                </h2>
                                {isactivePlan(item?.pricing,3) ? <p className="ribbon"><img src="assets/img/grey.png" /><span>Current Plan</span></p> : null}
                                <span class="monthly-txt">/quaterly</span>
                              </div>

                              {/* <p className="email-text text-center">1 sending mail</p> */}
                              <div className="range-btns  py-2">
                                <div className="quantity">
                                  <button
                                    className="minus"
                                    aria-label="Decrease"
                                    onClick={() => decNum(index)}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    className="input-box"
                                    value={num[index]}
                                    min="1"
                                    max="10"
                                    onChange={(e) => handleChange(e, index)}
                                  />
                                  <button
                                    className="plus"
                                    aria-label="Increase"
                                    onClick={() => incNum(index)}
                                  >
                                    +
                                  </button>
                                </div>
                                <div className="users-text">
                                  <p>Users</p>
                                </div>
                              </div>
                              <div>
                                <button
                                  className="btn btn-primary"
                                  onClick={(e) =>
                                    Stripe(
                                      e,
                                      getStriprId_ofPlan(item?.pricing, 3),
                                      3,
                                      num[index],
                                      item?._id
                                    )
                                  }
                                >
                                  Update to Quaterly
                                </button>
                              </div>
                            </div>
                          </div> : <div className=""></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <div className="payment-structure py-3">
                  <div className="row">
                    {paymentData.map((item, index) => {
                      return (
                        <>
                          {item.pricing.length > 0 && (
                            (item?.pricing[0]?.interval_count == 12 || item?.pricing[1]?.interval_count == 12 || item?.pricing[2]?.interval_count <= 12) ? <div className="col-md-4" key={index}>
                              <div className="price-plans">
                                <h4 className="plan-title">{item?.name}</h4>
                                <div className="package-price">
                                  <h2>
                                    $
                                    {num[index] *
                                      getamountofPlan(item?.pricing, 12)}
                                  </h2>
                                  {isactivePlan(item?.pricing,12) ? <p className="ribbon"><img src="assets/img/grey.png" /><span>Current Plan</span></p> : null}
                                  <span class="monthly-txt">/yearly</span>
                                </div>

                                {/* <p className="email-text text-center">1 sending mail</p> */}
                                <div className="range-btns  py-2">
                                  <div className="quantity">
                                    <button
                                      className="minus"
                                      aria-label="Decrease"
                                      onClick={() => decNum(index)}
                                    >
                                      -
                                    </button>
                                    <input
                                      type="number"
                                      className="input-box"
                                      value={num[index]}
                                      min="1"
                                      max="10"
                                      onChange={(e) => handleChange(e, index)}
                                    />
                                    <button
                                      className="plus"
                                      aria-label="Increase"
                                      onClick={() => incNum(index)}
                                    >
                                      +
                                    </button>
                                  </div>
                                  <div className="users-text">
                                    <p>Users</p>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) =>
                                      Stripe(
                                        e,
                                        getStriprId_ofPlan(item?.pricing, 12),
                                        12,
                                        num[index],
                                        item?._id
                                      )
                                    }
                                  >
                                    Update to Yearly
                                  </button>
                                </div>
                              </div>
                            </div> : <div className=""></div>
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="features-plan">
              <div className="d-flex feature-main feature-m-modal">
                <div className="table-feature-head-left">Features</div>
                <div className="table-feature-head-right row">
                  {paymentData?.length > 0 &&
                    paymentData.map((itm) => {
                      return (
                        <>
                          <div className="col">
                            <div className="text-capitalize">
                              {itm?.name}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="features-display mb-5">
                <div className="feature-display-left">
                  {
                    <>
                      <>
                        <div className="p-3">
                          {paidPlanFeatures.map((features, idx) => {
                            return (
                              <>
                                <div className={`mb-4  `} key={idx}>
                                  {features}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    </>
                  }
                </div>

                <div className="feature-display-right">
                  <div className="features-inside row">
                    {paymentData.map((item, index) => (
                      <>
                        <div className="feature-inside-one col">
                          <div className="feature-pointers">
                            <div className="p-3" key={index}>
                              {item?.features?.length > 0 &&
                                sortFeature(item?.features).map(
                                  (features, idx) => {
                                    return (
                                      <div
                                        className={`mb-4 ${features.isChecked ? "" : ""
                                          } `}
                                        key={idx}
                                      >
                                        <div className="circle-tick">
                                          <i
                                            className={` fa ${features.isChecked
                                              ? "fa-check  text_primary"
                                              : "fa-times"
                                              } `}
                                          ></i>
                                        </div>{" "}
                                        {/* {features.feature_name} */}
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    
    </>
  );
};

export default BillingDetailModal;
