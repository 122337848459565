import React from "react";
import { Modal, ModalFooter, ToastContainer } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Tooltip } from "antd";
import axios from "axios";
import environment from "../../environment";
import Pagination from "react-pagination-js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


import loader from "../../methods/loader";



const Invoicemodal = ({
  show,
  handleClose,
  paymentData,
  exportCsvv,
}) => {
  const navigate = useHistory();
  const user = useSelector((state) => state.user);
  const [tab, settab] = useState([]);
  const { type } = useParams()
  const [Total,setTotal] = useState(0);
  const [filters, setFilter] = useState({ page: 0, count: 10, search: '', isDeleted: false, status: '' })



  // Get the latest transaction
  const latestTransaction = paymentData?.[0];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const table = (p = {}) =>
  {
    let filter = { ...filters, ...p,user_id: user?.id||user?._id }
    loader(true)
    ApiClient.get(`transaction/all`,filter).then((res) => {
      if (res?.data) {
        settab(res?.data);
        setTotal(res?.total)
        }
      loader(false)
        
    });
  };
 
  const pageChange = (e) => {
    setFilter({...filters,page:e})
    table({page:e})
  };



  useEffect(() => {
    table({page:1});
 
  }, [filters]);

  const exportCsv = async () => {
    const token = localStorage.getItem("token")
    const res = await axios({
        method: "get",
        // url: `${environment.api}transaction/all?export_to_xls=yes&role${type}&count=${filters?.count}&user_id:${user?.id||user?._id}`,
        url: `${environment.api}transaction/all?export_to_xls=yes&count=${filters?.count}&user_id=${user?.id||user?._id}`,

        responseType: 'blob',
        body: { token: token }
    });
    var blob = new Blob([res.data], {
        type: res.headers["content-type"],
    });
    let downloadAnchor = document.createElement('a');
    downloadAnchor.href = window.URL.createObjectURL(blob);
    downloadAnchor.download = `Transactions.xlsx`;
    downloadAnchor.click()
}


// const exportCsvv = async (id) => {
//   const token = localStorage.getItem("token")
//   const res = await axios({
//       method: "post",
//       url: (`${environment.api}transaction/download-pdf`,{Id:id}),
//       responseType: 'blob',
//       body: { token: token }
//   });
//   var blob = new Blob([res.data], {
//       type: res.headers["content-type"],
//   });
//   let downloadAnchor = document.createElement('a');
//   downloadAnchor.href = window.URL.createObjectURL(blob);
//   downloadAnchor.download = `Transactions.pdf`;
//   downloadAnchor.click()
// }

const stripe = (invoice_url)=>
{
 window.open(invoice_url);

}

  return (
    <>
      <Modal
        className={"modal_width payment-modal"}
        show={show}
        onHide={handleClose}
        dialogClassName="modal-60w"
      >
        <Modal.Header closeButton className="align-items-center">
          <Modal.Title>Current Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      

<div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-objective"
                    role="tabpanel"
                    aria-labelledby="pills-objective-tab"
                  >
                    <div className="mvp_table"> 
                      <div className={tab?.length>4?"scrollTable table-responsive":" table-responsive"}>
                        <table className="table table-hover mt-3">
                          <thead>
                            <tr>
                          
                              <th scope="col">Monthly Cost</th>
                              <th scope="col">Transaction Date</th>
                              <th scope="col">Seat</th>
                              <th scope="col">Paid Amount</th>
                              <th scope="col">Download</th>
                     
                            </tr>

                          </thead>
                          {
                            <tbody>
                              {tab &&
                                tab.map((item,i) => {
                                  return (
                                    <tr key={item.id}>
                                    
                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        ${item?.amount}
                                      </td>
                                       
                                      <td>
                                        {item?.createdAt
                                          ? (() => {
                                            const createdAtDate = new Date(
                                              item.createdAt
                                            );
                                            const formattedDate =
                                              createdAtDate
                                                .toISOString()
                                                .split("T")[0];
                                            return formattedDate;
                                          })()
                                          : "--"}
                                      </td>

                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        {item?.seats || "--"}
                                      </td>
                                      {/*  */}

                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        ${item.amount|| "0"}
                                      </td>

                                    
                                      
                                      <td>
                                      <Tooltip placement="top" title="Download">
                                        {/* <span className="text-capitalize d-flex align-items-center" /> */}
                                        <button
                                          // onClick={(e) => exportCsvv(item?.id||item?._id)} // Pass the item.id as the transactionId
                                          // className="btn  invoice_download"

                                          onClick={(e)=>stripe(item?.invoice_url)} // Pass the item.id as the transactionId
                                          className="btn  invoice_download"
                                        >
                                            <i className="fa fa-download  pointer"></i> 
                                        </button>
                                    
                                      </Tooltip>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          }
                          
                        
                        </table>
                        
                      </div>
    
                    </div>
                   
                    
                  </div>
                  {Total > filters.count && (
    <div className="paginationWrapper">
      <span>
        Show {filters.count} from {Total} records
      </span>
      <Pagination
        currentPage={filters.page}
        totalSize={Total}
        sizePerPage={filters.count}
        changeCurrentPage={pageChange}
        theme="bootstrap"
      />
      
    </div>

  )}
                </div>

              
        </Modal.Body>
        <ModalFooter>
          <div>
             <button
              onClick={(e) => exportCsv(latestTransaction?.id)}
              className="btn btn-primary invoice_download px-3"
            >
              <i className="fa fa-download me-1 pointer"></i> Download
            </button> 
          </div>
        </ModalFooter>
      </Modal>

      <ToastContainer position="top-right" />
    </>
  );
 
};

export default Invoicemodal;
