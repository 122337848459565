import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import Select from "react-select";
import loader from "../../methods/loader";
import moment from "moment";
import environment from "../../environment";
import methodModel from "../../methods/methods";
import Pagination from "react-pagination-js";
import { FaUserPlus } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa6";
import { login_success } from "../../actions/user";
import Multiselect from "multiselect-react-dropdown";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import { LiaUserTieSolid } from "react-icons/lia";


const EmployeesListing = () => {
  const navigate = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.search);
  const [Employeedata, setEmployeedata] = useState();
  const [data, setdata] = useState();
  const [data1, setdata1] = useState();
  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    status: "",
    search: "",
  });
  const [loading, setloading] = useState(false);
  const [Employee_total, setEmployee_total] = useState(0);
  const [total, settotal] = useState(0);
  const [total1, settotal1] = useState(0);
  const [totalUser, setTotalUser] = useState([]);
  const [userData, setuserData] = useState();

  useEffect(() => {
    getData();
    getData1();
    getEmployeesData();
    getManagers();
    getTeam();
    getuserData();
  }, []);

  const getuserData = () => {
    let payload = {
      id: user?.id,
    };
    ApiClient.get(`profile`, payload).then((res) => {
      if (res?.success) {
        let u={...user,...res.data}
        setuserData(u);
        dispatch(login_success(u))
      }
    });
  };

  const getEmployeesData = (p = {}) => {
    let filt = { ...filter, ...p };
    setloading(true);
    if (user?.role == "employee") {
      filt.addedBy= user?.addedBy
    } else {
      filt.addedBy= user?.id || user?._id
    }
    ApiClient.get(`users/list`, filt).then((res) => {
      if (res.success) {
        setEmployeedata(res?.data);
        setEmployee_total(res?.total);
      }
      setloading(false);
    });
  };
  const getData = (p = {}) => {
    let filt = { ...filter, ...p };
    setloading(true);
    ApiClient.get(`listing`, filt).then((res) => {
      if (res.success) {
        setdata(res?.data);
        settotal(res?.total);
      }
      setloading(false);
    });
  };
  const getData1 = (p = {}) => {
    let filt = { ...filter, ...p, addedBy: user?.id };
    setloading(true);
    ApiClient.get(`invites`, filt).then((res) => {
      if (res.success) {
        setdata1(res?.data);
        settotal1(res?.total);
      }
      setloading(false);
    });
  };

  useEffect(() => {
    if (user && user.loggedIn && searchState.data) {
      setfilter({ ...filter, search: searchState.data });
      getEmployeesData({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  const getManagers = () => {
    // ApiClient.get(`users/list?status=active&isManager=true`).then((res) => {
    ApiClient.get(`users/list?status=active&addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        setmanagers(
          res?.data?.map((item) => {
            return { value: item?.id || item?._id, label: item?.fullName };
          })
        );
      }
    });
  };
  const getTeam = () => {
    ApiClient.get(`teams?addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        setteams(
          res?.data?.map((item) => {
            return { id: item?.id || item?._id, id: item?.id || item?._id, label: item?.fullName||"",name:item?.fullName ,managerName:item?.managerEmail};
            // **********************
          })
        );
      }
    });
  };

  const handleDelete = (id) => {
    if (window.confirm("Do you really want to delete this employee")) {
      ApiClient.delete(`user/delete?id=${id}`).then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          getData();
          getEmployeesData();
          setTimeout(() => {
            getuserData();

          }, 1000);
        }
      });
    }
  };

  const [form, setform] = useState({
    fullName: "",
    managerId: "",
    managerAndTeam: "",
    // isManager:true,
    teamId: "",
    description: "",
    email: "",
    image: "",
    role: "employee",
  });
  const [managers, setmanagers] = useState();
  const [teams, setteams] = useState();

  
  const trialCheck=()=>{
    return methodModel.trialCheck(user)
  }

  const addCheck=!(
    (user?.availiableSeats>0&&user?.planData?.plan_type=='free'&&trialCheck())
    ||(trialCheck()&&!user?.planAmountPaid&&user?.planData?.plan_type=='paid')
    ||(user?.planData?.plan_type=='paid'&&user?.planAmountPaid&&user?.availiableSeats>0))

  const handleCreateEmployee = (e) => {
    e.preventDefault();
    let payload = {
      ...form,
      // managerId: form?.managerId?.map(itm=>(itm.value)),
      // teamId: form?.teamId.map(itm=>(itm.value)),
      teamId: form?.teamId,
    };

    if (addCheck) {
      document.getElementById("CloseEmployeeModal").addEventListener("click", function () {
        document.getElementById("EmployeeModal").style.display = "none";
      });
      navigate.push("/billing-details?plan=true");
    } else {
      if (!form?.id) {
        ApiClient.post(`add/user`, payload).then((res) => {
          console.log(payload,"payload");
          if (res.success) {
            setTimeout(() => {
              toast.success("Team enriched! New member added! 🙌");
            }, 100);
            getEmployeesData();
            getuserData();
            document.getElementById("CloseEmployeeModal").click();
          }
        });
      } else {
        ApiClient.put(`edit/profile`, payload).then((res) => {
          if (res?.success) {
            setTimeout(() => {
              toast.success(res?.message);
            }, 100);
            getEmployeesData();
            document.getElementById("CloseEmployeeModal").click();
          }
        });
      }
    }
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", {
      file: file,
      modelName: "users",
    }).then((res) => {
      if (res.success) {
        let image = res.data.fullpath;
        setform({ ...form, image: image });
      } else {
        setform({ ...form, image: "" });
      }
      loader(false);
    });
  };

  const clearFields = () => {
    setform({
      fullName: "",
      managerId: "",
      teamId: "",
      description: "",
      email: "",
      image: "",
      role: "employee",
    });
  };

  // *********************************************************************

  const handleEdit = (id) => {
    // window.alert("hgrhei")
    ApiClient.get(`user/details?id=${id}`).then((res) => {
      if (res.data) {
        let newmanagerAndTeam=res?.data?.managerAndTeam.filter(item=>(item.managerName!=""))
        setform({
          id: res?.data?.id,
          fullName: res?.data?.fullName,
          email: res?.data?.email,
          managerId: newmanagerAndTeam?.map((item) => {
            return { value: item?.id, label: item?.fullName};
          }),
          teamId: res?.data?.managerAndTeam?.map((item) => {
            return item?.team?.id;
          }),
          image: res?.data?.image,
        });
        document.getElementById("OpenEmployeeModal").click();

      }
    });
  };
  const pageChange = (e) => {
    setfilter({ ...filter, page: e, count: 10 });
    getEmployeesData({ page: e });
  };

  const sortBy = (e) => {
    setfilter({ ...filter, sortBy: e });
    getEmployeesData({ sortBy: e });
  };

  const EmployeeInTeamFilter = (e) => {
    setfilter({ ...filter, inTeam: e });
    getEmployeesData({ inTeam: e });
  };

  const changeStatus = (itm) => {
    let status = "active";
    if (itm?.status == "active") {
      status = "deactive";
    }
    if (window.confirm("Do you want to change status for this employee")) {
      ApiClient.put(
        `change/status?model=users&id=${itm?._id}&status=${status}`
      ).then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          getEmployeesData();
        }
      });
    }
  };

  const BuyPlan = () => {
    navigate.push("/billing-details");
  };

  return (
    <>
      <Layout>
        <div className="inners_wrapper">
         {user?.role != "employee" && <div className="d-flex justify-content-between my-2 w-100">
            <div className="d-flex">
              <select
                className=" form-control select-width"
                value={
                  filter?.inTeam == "true"
                    ? "true"
                    : filter?.inTeam == "false"
                      ? "false"
                      : "All"
                }
                onChange={(e) => EmployeeInTeamFilter(e.target.value)}
              >
                <option className=" category " value="">
                  All
                </option>
                <option className=" category" value="true">
                  With Team
                </option>
                <option className=" category" value="false">
                  Without Team
                </option>
              </select>

              <div className="seats-available ms-2">
                {" "}
                <div class=" seats-dropdown">
                  <div class="main-seats">
                    <div class="dropdown-items border-right " href="#">
                      <div>
                        <FaUserCheck />

                        <span>Used Seats</span>
                      </div>
                      <span className="hightlight-seats ms-2">
                        {userData?.usedSeats||1}
                      </span>
                    </div>
                    <div class="dropdown-items" href="#">
                      <div>
                        <FaUserPlus />

                        <span>Total Seats </span>
                      </div>
                      <span className="hightlight-seats ms-2">
                        {userData?.numberOfSeats}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {userData ? (
              <span>

                <button
                  className="btn btn-primary btn-sm mr-2"
                  onClick={(e) => clearFields()}
                  data-toggle="modal"
                  data-target="#EmployeeModal"
                  disabled={addCheck}
                >
                  <i className="fa fa-plus"></i> Add Employee
                </button>


{addCheck?<>
  <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => BuyPlan()}
                >
                  <i className="fa fa-plus"></i> Add Seats
                </button>
                {!userData?.availiableSeats ? (
                  <div class="new_seats">Add seats in your Plan</div>
                ) : (
                  <div></div>
                )}
</>:<></>}
                
                {/* {" "}
              {userData?.availiableSeats ? (
          
               
              ) : (

              )} */}
              </span>
            ) : (
              ""
            )}
          </div>}
          {/* <span>
            <small>Seats Used </small>
            {userData?.usedSeats} / <small>Available Seats </small>
            {userData?.availiableSeats}
          </span> */}
          <div className="teams_data_wraps">
            <div className="tabs_start mt-4">
              {/* <ul
                                className="nav nav-pills okrs_tabsul mb-3"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="pills-Employee-tab"
                                        data-toggle="pill"
                                        data-target="#pills-Employee"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                    >
                                        Employees
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="pills-OKRs-tab"
                                        data-toggle="pill"
                                        data-target="#pills-OKRs"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="false"
                                    >
                                        Employees Teams
                                    </button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="pills-details-tab"
                                        data-toggle="pill"
                                        data-target="#pills-details"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                    >
                                        Invites
                                    </button>
                                </li>
                            </ul> */}
              <div className="tab-content " id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-Employee"
                  role="tabpanel"
                  aria-labelledby="pills-Employee-tab"
                >
                  <section>
                    {!loading ? (
                      <div className="mvp_table">
                        <div className=" table-responsive">
                          <table className="table table-hover mt-3">
                            <thead>
                              <tr>
                                <th scope="col">Image</th>
                                <th scope="col">
                                  Employee Name{" "}
                                  {filter?.sortBy === "fullName asc" ? (
                                    <i
                                      className="fa fa-arrow-up pl-3"
                                      title=" Z-A "
                                      onClick={(e) => sortBy("fullName desc")}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-arrow-down pl-3"
                                      title=" A-Z "
                                      onClick={(e) => sortBy("fullName asc")}
                                    ></i>
                                  )}
                                </th>
                                <th scope="col">Email</th>
                                {/* <th scope="col">Manager</th> */}
                                <th scope="col">Team</th>
                                <th scope="col">Status</th>
                                {user?.role != 'employee' &&<th scope="col">Action</th>}
                              </tr>
                            </thead>
                            {loading ? null : (
                              <tbody>
                                {Employeedata &&
                                  Employeedata.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <span className="text-capitalize d-flex align-items-center">
                                            <img
                                              src={methodModel.userImg(
                                                item?.image
                                              )}
                                              className="mr-2 pointer"
                                              title="View"
                                              onClick={(e) => {
                                                navigate.push(
                                                  `/employee/${item?.id || item?._id}`
                                                );
                                              }}
                                              width="45px"
                                            />
                                          </span>
                                        </td>
                                        <td>
                                          <span
                                            className="fs-5 text-capitalize mr-2 pointer"
                                            title="View"
                                            onClick={(e) => {
                                              navigate.push(
                                                `/employee/${item?.id || item?._id}`
                                              );
                                            }}
                                          >
                                            {item?.fullName?item?.fullName:item?.email.split('@')[0]}
                                            {/* {item?.fullName || "--"} */}
                                          </span>{" "}
                                        </td>
                                        <td>
                                          <span className="fs-5">
                                            {item?.email || item?.employeeEmail}
                                          </span>{" "}
                                        </td>
                                        {/*<td>

                                         <div className="">
                                            {item?.employee_details?.length > 0
                                              ? item?.employee_details.map(
                                                  (itm) => {
                                                    
                                                    return itm?.mangerName ? (
                                                      <span className=" text-capitalize d-flex pb-1">
                                                         <div className="common-icon me-1">
                                                          <svg
                                                            stroke="currentColor"
                                                            fill="none"
                                                            stroke-width="1.5"
                                                            viewBox="0 0 24 24"
                                                            aria-hidden="true"
                                                            height="1em"
                                                            width="1em"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                                            ></path>
                                                          </svg>
                                                        </div>
                                                        
                                                        {itm?.mangerName}{" "}
                                                        
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    );
                                                  }
                                                )
                                              : "No Manager"}
                                          </div>
                                          {/* {item?.manager_details?.length>0?item?.manager_details.map(itmm=>{
                                          
                                            return <span>{itmm?.Manager}</span>
                                        }):""} 
                                        </td> */}

                                        <td>
                                          <div className="">
                                            {item?.employee_details?.length > 0?<>
                                            {item?.employee_details.map(
                                                (itm) => {
                                                // console.log(itm,"team listing");
      
                                                  return  (
                                                    <span className=" text-capitalize d-flex pb-1">
                                                      <div className="common-icon me-1">
                                                        <svg
                                                          stroke="currentColor"
                                                          fill="none"
                                                          stroke-width="1.5"
                                                          viewBox="0 0 24 24"
                                                          aria-hidden="true"
                                                          height="1em"
                                                          width="1em"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                                          ></path>
                                                        </svg>
                                                      </div>
                                                      {itm?.teamName ||'Unidentified'}
                                                      {itm?.managerName ? (
                                                        <>
                                                       
                                                          
                                                          <div className="common-icon  ms-3 mamnger-icon">
                                                          <LiaUserTieSolid />


                                                          </div>
                                                          {" managed by "}
                                                          {itm?.managerName}
                                                        </>
                                                      ) : (
                                                        <>
                                                        </>
                                                      )}
                                                    </span>
                                                  );
                                                }
                                              )}
                                            </> 
                                            : <p className="fs-14">Without Team</p>
                                             }
                                          </div>
                                        </td>

                                        {/*******************************************/}

                                        {/********************************************/}

                                        <td>
                                          <div className="employee-status">
                                            <button
                                              className={
                                                item?.status == "deactive"
                                                  ? "btn badge badge-danger text-capitalize"
                                                  : "btn badge badge-success text-capitalize"
                                              }
                                              disabled={
                                                item?.status == "pending" ||
                                                userData?.email == item?.email
                                              }
                                              onClick={user?.role == 'employee' ? '' :(e) =>
                                                changeStatus(item)
                                              }
                                            >
                                              {item?.status=="deactive"?"inactive":item.status}
                                            </button>
                                          </div>
                                        </td>

                                        {user?.role != 'employee' && <td>
                                           <div class="dropdown">
                                            <span
                                              class=" text-dark "
                                              type="button"
                                              data-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i
                                                class="fa fa-ellipsis-v text-dark"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <div class="dropdown-menu">
                                              {!addCheck?<>
                                                <span class="  dropdown-item pointer"
                                                onClick={(e) =>
                                                  handleEdit(item?._id || item?._id)
                                                }>
                                                <i
                                                  class="fa fa-pen text-dark me-2"
                                                  aria-hidden="true"
                                                ></i>
                                                Edit
                                              </span>
                                              </>:<></>}
                                              
                                              {userData?.email == item?.email ? (
                                                ""
                                              ) : (
                                                <span class="dropdown-item text-danger  pointer"
                                                  onClick={(e) =>
                                                    handleDelete(item?._id)
                                                  }>
                                                  <i
                                                    class="fa fa-trash  text-dark me-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                  Delete
                                                </span>)}
                                            </div>
                                          </div>



                                        </td>}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    ) : null}
                    {!loading && Employeedata?.length == 0 ? (
                      <div className="py-3 text-center no-data"><img src="/assets/img/no-data-logo.svg" />No Data Found</div>
                    ) : (
                      <></>
                    )}
                    {!loading && Employee_total > filter.count ? (
                      <div className="paginationWrapper">
                        <span>
                          Show {filter.count} from {Employee_total} records
                        </span>
                        <Pagination
                          currentPage={filter.page}
                          totalSize={Employee_total}
                          sizePerPage={filter.count}
                          changeCurrentPage={pageChange}
                          theme="bootstrap"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {loading ? (
                      <div className="d-flex justify-content-center py-4">
                        <img src="/assets/img/loader.gif" width="40px" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-OKRs"
                  role="tabpanel"
                  aria-labelledby="pills-OKRs-tab"
                >
                  <section>
                    {!loading ? (
                      <div className="mvp_table">
                        <div className=" table-responsive">
                          <table className="table table-hover mt-3">
                            <thead>
                              <tr>
                                {/* <th scope="col"></th> */}
                                <th scope="col">Image</th>
                                <th scope="col">Employee Name</th>
                                <th scope="col">Manager Name</th>
                                <th scope="col">Team</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            {loading ? null : (
                              <tbody>
                                {data &&
                                  data.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <span className="text-capitalize d-flex align-items-center">
                                            <img
                                              src={methodModel.userImg(
                                                item?.image
                                              )}
                                              className="mr-2 pointer"
                                              onClick={(e) => {
                                                navigate.push(
                                                  `/employee/${item?.id}`
                                                );
                                              }}
                                              width="45px"
                                            />
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-5 text-capitalize">
                                            {item?.employeeName || "--"}
                                          </span>
                                        </td>
                                        <td>{item?.mangerName || "--"}</td>
                                        <td>{item?.teamName || "--"}</td>
                                        {/* <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td> */}

                                        <td>
                                          <span
                                            onClick={(e) =>
                                              handleEdit(item?.id)
                                            }
                                          >
                                            <i
                                              className="fa fa-pen me-2"
                                              title="Edit"
                                            ></i>
                                          </span>

                                          <span
                                            onClick={(e) =>
                                              handleDelete(item?.id)
                                            }
                                          >
                                            <i
                                              className="fa fa-trash me-2"
                                              title="Delete"
                                            ></i>
                                          </span>
                                        </td>

                                        {/* ******************************************************************** */}
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    ) : null}
                    {!loading && total == 0 ? (
                      <div className="py-3 text-center">No Data Found</div>
                    ) : (
                      <></>
                    )}
                    {!loading && total > filter.count ? (
                      <div className="paginationWrapper">
                        <span>
                          Show {filter.count} from {total} records
                        </span>
                        <Pagination
                          currentPage={filter.page}
                          totalSize={total}
                          sizePerPage={filter.count}
                          changeCurrentPage={pageChange}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {loading ? (
                      <div className="d-flex justify-content-center py-4">
                        <img src="/assets/img/loader.gif" width="40px" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-details"
                  role="tabpanel"
                  aria-labelledby="pills-details-tab"
                >
                  <section>
                    {!loading ? (
                      <div className="mvp_table">
                        <div className=" table-responsive">
                          <table className="table table-hover mt-3">
                            <thead>
                              <tr>
                                <th scope="col">Employee Email</th>
                                <th scope="col">Manager </th>
                                <th scope="col">Team</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            {loading ? null : (
                              <tbody>
                                {data1 &&
                                  data1.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <span className="fs-5">
                                            {item?.employeeEmail || "--"}
                                          </span>
                                        </td>
                                        <td>{item?.managerEmail || "--"}</td>
                                        <td className="text-capitalize">
                                          {item?.teamDetails
                                            ? item?.teamDetails?.fullName
                                            : "--"}
                                        </td>
                                        <td> {item?.status=="deactive"?"inactive":item.status}</td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    ) : null}
                    {!loading && total1 == 0 ? (
                     <div className="py-3 text-center no-data"><img src="/assets/img/no-data-logo.svg" />No Data Found</div>
                    ) : (
                      <></>
                    )}
                    {!loading && total1 > filter.count ? (
                      <div className="paginationWrapper">
                        <span>
                          Show {filter.count} from {total} records
                        </span>
                        <Pagination
                          currentPage={filter.page}
                          totalSize={total1}
                          sizePerPage={filter.count}
                          changeCurrentPage={pageChange}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {loading ? (
                      <div className="d-flex justify-content-center py-4">
                        <img src="/assets/img/loader.gif" width="40px" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create Employee Modal */}
        <button
          className="btn btn-primary btn-sm d-none"
          id="OpenEmployeeModal"
          data-toggle="modal"
          data-target="#EmployeeModal"
        >
          <i className="fa fa-plus"></i> Add Employee
        </button>
        <div
          className="modal fade"
          id="EmployeeModal"
          data-backdrop="static"
          tabindex="-1"
          role="dialog"
          aria-labelledby="EmployeeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-title">
                <div className="p-3 d-flex justify-content-between align-items-start">
                  <div>
                    <h3 className="modal-title " id="EmployeeModalLabel">
                      {form.id ? "Update Employee" : "Create Employee"}
                    </h3>
                    <p className="mb-1">
                      All required fields are marked with an asterisk (
                      <span className="text-danger">*</span>).
                    </p>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <form onSubmit={handleCreateEmployee}>
                <div className="modal-body">
                  <div className="">
                    <div className="row">
                      <div className="col-md-3 my-2">
                        <label>
                          Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-9 my-2">
                        <input
                          type="text"
                          // value={form?.fullName?form?.fullName:form?.email.split("@")[0]}
                          value={form?.fullName?form?.fullName:''}
                          onChange={(e) =>
                            setform({ ...form, fullName: e.target.value })
                          }
                          className="form-control"
                          placeholder="Employee Name"
                          required
                        />
                      </div>
                      <div className="col-md-3 my-2">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-9 my-2">
                        <input
                          type="email"
                          value={form?.email}
                          onChange={(e) =>
                            setform({ ...form, email: e.target.value })
                          }
                          className="form-control"
                          placeholder="Enter Employee Email"
                          disabled={form.id}
                          required
                        />
                      </div>
                      <div className="col-md-3 my-2">
                        <label>
                          Manager <span className="text-danger"></span>
                        </label>
                      </div>

                      <div className="col-md-9 my-2">
                        <div className="select-color-box">

                          {/* <Select
                            value={form?.managerId}
                            className={managers?.length > 4 ? "" : "    "}
                            isOptionSelected={true}
                            isClearable={true}
                            onChange={(e) => setform({ ...form, managerId: e })}
                            options={managers}
                            placeholder="Select Manager"
                          isDisabled={form?.id}
                          /> */}
                          <div className="multiselect-custom multiselect-manager">
                            <p className=" border  p-1 ">{form?.teamId?.length>0?form?.teamId?.map(id=>{
                              return <span className="mr-2 border rounded p-1">{teams.find(itm=>itm.id==id)?.managerName||"--"}</span>
                            }):<></>}</p>
                              {/* <Multiselect
                                disable={form?.id}
                                // clearable={false}
                                avoidHighlightFirstOption={true}
                                selectedValues={form?.managerId}
                                options={managers}
                                displayValue={"label"}
                                onSelect={e => setform({ ...form, managerId: e })}
                                onRemove={e => setform({ ...form, managerId: e })}
                              /> */}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 my-2">
                        <label>
                          Team<span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-md-9 my-2">
                        
                         <div className="multiselect-custom">
                          <MultiSelectDropdown
                          options={teams}
                          intialValue={form.teamId}
                          displayValue="name"
                          result={e=>{
                            setform({ ...form, teamId: e.value })
                          }}
                          />
                              {/* <Multiselect
                                avoidHighlightFirstOption={true}
                                selectedValues={form?.teamId}
                                options={teams}
                                displayValue={"label"}
                                onSelect={e => setform({ ...form, teamId: e })}
                                onRemove={e => setform({ ...form, teamId: e })}
                              /> */}
                          </div>
                      </div>
                     

                      <div className="col-md-3 my-2">
                        <label>Image</label>
                      </div>
                      <div className="col-md-9 my-2">
                        <div className="maininutcls">
                          <label className="profileImageLabel me-3">
                            {form?.image ? (
                              <img
                                src={`${environment?.api}images/users/${form?.image}`}
                                className="profileImage"
                              />
                            ) : (
                              <img
                                src="/assets/img/placeholder.png"
                                className="profileImage"
                              />
                            )}
                          </label>
                          <div className="profile_btn mt-2">
                            <div>
                              <label className="btn btn-primary btn-sm edit me-3 mb-0">
                                <input
                                  id="bannerImage"
                                  type="file"
                                  className="d-none"
                                  accept="image/*"
                                  value={form.baseImg ? form.baseImg : ""}
                                  onChange={(e) => {
                                    uploadImage(e);
                                  }}
                                />
                                {form.image ? "Change" : "Upload"} Image
                              </label>
                            </div>
                            <div>
                              {form.image ? (
                                <label
                                  className="btn btn-danger btn-sm  delete me-3 mb-0"
                                  onClick={(e) =>
                                    setform({ ...form, image: "" })
                                  }
                                >
                                  Remove Image
                                </label>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="mt-4">
                  <button
                      type="button"
                      id="CloseEmployeeModal"
                      className="btn btn-secondary mr-2"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      //              id="OpenEmployeeModal"
                      // data-toggle="modal"
                      // data-target="#EmployeeModal"
                      type="submit"
                      disabled={!form?.fullName || !form?.email || !form?.teamId}
                      className="btn btn-primary btn-md "
                    >
                      {form.id ? "Update" : "Create"}
                    </button>
                  
                    
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EmployeesListing;
