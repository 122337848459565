import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Modal, ToastContainer } from "react-bootstrap";
import { toast, useToast } from "react-toastify";

import ApiClient from "../../methods/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../methods/loader";
import Swal from "sweetalert2";
import { login_success } from "../../actions/user";
import { useHistory } from "react-router-dom";

const PaymentModal = ({
  show,
  handleClose,
  handleShow,
  paymentData,
  getData,
  pricing,
}) => {
  const user = useSelector((state) => state.user);
  const [form, setForm] = useState({});
  const [cardList, setCardList] = useState([]);
  const [numOfSeates, setNumOfSeates] = useState(1);
  const [submit, setSubmit] = useState(false);
  const [selectCard, setSelectCard] = useState({});
  const [isCardSelect, setIsCardSelect] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [num, setNum] = useState(1);
  const [data, setdata] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (paymentData?.plan_type == "free") {
      setNumOfSeates(paymentData?.numberOfPeople);
    }
    setdata(data?.isActive);
  }, []);

  const dispatch = useDispatch();

  useEffect(()=>{
    setNum(user?.totalAddedUsers || 1)
  },[user?.totalAddedUsers])

  const handleSubmit = (e) => {
    e.preventDefault(e);
    setSubmit(true);

    if (
      !dateType("card_number", form?.card_number) ||
      !dateType("cvc", form?.cvc) ||
      !dateType("month", form?.exp_month) ||
      !dateType("year", form?.exp_year)
    )
      return;

    let value = {
      ...form,
    };
    loader(true);
    ApiClient.post("card", value).then((res) => {
      if (res.success) {
        // toast.success('Add Card Successfully')
        getAllCard();
        setForm({ card_number: "", cvc: "", exp_month: "", exp_year: "" });
        setSubmit(false);

        let value = {
          planId: paymentData?._id || paymentData?.id,
          planType: paymentData?.plan_type,
          planInterval: pricing?.interval_count,
          stripe_price_id:
            paymentData?.plan_type == "free"
              ? paymentData.stripe_price_id
              : pricing?.stripe_price_id,
          paidAmount:
            paymentData?.plan_type == "free"
              ? 0
              : pricing?.unit_amount * Number(numOfSeates),
          cardId: res?.data?.cardId,
          seats: Number(numOfSeates),
          isTrial:paymentData?.trial?'yes':'no',
          freePlanBuy: paymentData?.plan_type == "free" ? true : false,
        };
        ApiClient.post("subscription", value).then((res) => {
          if (res.success) {
            setTimeout(() => {
              toast.success(res?.message);
            }, 100);
            handleClose();
            getAllCard();
            gallaryData();
            history.push("/teams");
          }
          loader(false);
        });
      }
    });
  };

  const dateType = (type, data) => {
    let isTrue = false;

    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();

    let isYear = currentYear <= data;

    let month = moment(currentDate).format("MM");

    let isMonth =
      form?.exp_year > currentYear
        ? data <= "12"
        : month <= data && data <= "12";

    let cvc = data?.length == 3;

    let cardNumber = data?.length == 16;

    const dateType =
      type == "year"
        ? isYear
        : type == "month"
          ? isMonth
          : type == "card_number"
            ? cardNumber
            : cvc;

    if (!data) {
      isTrue = false;
    } else if (dateType) {
      isTrue = true;
    } else {
      isTrue = false;
    }

    return isTrue;
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete("card", { card_id: id }).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getAllCard();
          }
          loader(false);
        });
      }
    });
  };

  const getAllCard = () => {
    loader(true);
    ApiClient.get("card").then((res) => {
     
      if (res.success) {
        setCardList(res.data);
        res?.data?.length < 1 && setIsAddCard(true);
        if (res?.data?.length == 1) {
          setIsCardSelect(true);
          setSelectCard({ ...res.data[0] });
        }
      }
      loader(false);
    });
  };

  // useEffect(() => {
  //     getAllCard()
  // }, [])

  const SetPrimaryCard = (id) => {
    let value = {
      card_id: id,
    };

    loader(true);
    ApiClient.put("card/set-primary", value).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        getAllCard();
      }
      loader(false);
    });
  };

  const SubmitPayment = (e) => {
    e.preventDefault();
    setSubmit(true);

    let value = {
      planId: paymentData?._id || paymentData?.id,
      planType: paymentData?.plan_type,
      planInterval: pricing?.interval_count,
      stripe_price_id:
        paymentData?.plan_type == "free"
          ? paymentData.stripe_price_id
          : pricing?.stripe_price_id,
      paidAmount:
        paymentData?.plan_type == "free"
          ? 0
          : pricing?.unit_amount * Number(numOfSeates),
      cardId: selectCard?.card_id,
      seats: Number(numOfSeates),
      freePlanBuy: paymentData?.plan_type == "free" ? true : false,
    };

    loader(true);
    ApiClient.post("subscription", value).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        handleClose();
        // getAllCard()
        gallaryData();
        history.push("/teams");
      }
      loader(false);
    });
  };

  const Stripe = (e, stripId, interCount) => {
    e.preventDefault();
    let url = "payment";

    const payload = {
      isTrial:paymentData?.trial?'yes':'no',
      plan_id: paymentData?._id || paymentData?.id,
      user_id: user?.id,
      stripe_price_id: stripId || paymentData?.pricing[0]?.stripe_price_id,
      interval_count: interCount || paymentData?.pricing[0]?.interval_count,
      seats: Number(num),
    };
   
    loader(true);
    ApiClient.post(url, payload).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        handleClose();
        gallaryData();

        window.location.assign(res?.data?.url);
      }
      loader(false);
    });
  };

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        let u={...user,...res.data}
        dispatch(login_success(u));
      }
      loader(false);
    });
  };

  const incNum = () => {
    if (num < 10) {
      setNum(num + 1);
    }
  };

  const decNum = () => {
    if (num > 0) {
      let totalAddedUsers=user?.totalAddedUsers||1
      if(num<=totalAddedUsers){
        toast.error(`There are ${totalAddedUsers} active employees, to reduce the number of seats, please remove or deactivate some employees`)
        return
      }
      setNum(num - 1);
    }
  };

  const handleChange = (e) => {
    setNum(e.target.value);
  };

  const getamountofPlan = (plan, interval) => {
    if (plan) {
      let pricing = [...plan]
      let amount = 0
      pricing?.filter(itm => {
        if (itm?.interval_count == interval) {
          amount = itm?.unit_amount || O
        }
      })
     
      return Number(amount)
    } else {
      return 0
    }
  }

  const getStriprId_ofPlan = (plan, interval) => {
    if (plan) {
      let pricing = [...plan]
      let stripe_id = ""
      pricing?.filter(itm => {
        if (itm?.interval_count == interval) {
          stripe_id = itm?.stripe_price_id || ""
        }
      })
      
      return (stripe_id)
    } else {
      return ""
    }
  }

  return (
    <>
      <Modal
        className={"modal_width payment-modal"}
        show={show}
        onHide={handleClose}
        dialogClassName="modal-30w"
      >
        <Modal.Header closeButton className="align-items-center">
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="payment_wrapper">
            <div className="adds_plan">
              <div className="row d-none">
                <div className="col-12">
                  <h5>Buy More Seats</h5>
                </div>
                <div className="flex justify-content-between mb-3">
                  <div className="inner_text">
                    <p>Add More Seats</p>
                  </div>
                  <div className="seatsfilelds">
                    <div className="inputs_brap">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Seates You Want Purchese"
                        min="1"
                        value={numOfSeates}
                        onChange={(e) => setNumOfSeates(e.target.value)}
                        disabled={
                          paymentData?.plan_type == "free" ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Seat(${pricing?.unit_amount})</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Seates You Want Purchese"
                  min="1"
                  value={numOfSeates}
                  onChange={(e) => setNumOfSeates(e.target.value)}
                  disabled={paymentData?.plan_type == "free" ? true : false}
                />
              </div>

              <div className="form-group">
                <div className="payment_text pb-0 payment-total">
                  <label>Total Amount ($)</label>
                  <div className="total_amt ">
                    {" "}
                    {pricing?.unit_amount * numOfSeates}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-10 mx-auto">
              <ul
                class="nav nav-pills okrs_tabsul mb-4 align-items-center justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    data-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <LuCalendar />
                    Monthly
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    data-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <LuCalendar />
                    Quaterly
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-contact-tab"
                    data-toggle="pill"
                    data-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    <LuCalendar />
                    Yearly
                  </button>
                </li>
              </ul>
            </div>
          </div> */}
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="payment-structure py-3">
                <div className="row">
                  <div className="col-md-9 mx-auto">
                    {paymentData?.pricing?.[0]?.interval_count == 1 ? <div className="price-plans">
                      <h4 className="plan-title">{paymentData?.name}</h4>
                      <div className="package-price  align-items-center">
                        <h2>
                          ${num * getamountofPlan(paymentData?.pricing, 1)}
                        </h2>
                        <span>/Monthly</span>
                      </div>
                      {/* <p className=" email-text text-center">1 sendind mail</p> */}
                      <div className="range-btns  py-2">
                        <div class="quantity">
                          <button
                            class="minus"
                            aria-label="Decrease"
                            onClick={decNum}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            class="input-box"
                            // value="1"
                            value={num}
                            min="1"
                            max="10"
                            onChange={handleChange}
                          />
                          <button
                            class="plus"
                            aria-label="Increase"
                            onClick={incNum}
                          >
                            +
                          </button>
                        </div>
                        <div className="users-text">
                         
                          <p>{num <= 1 ? "Seat" : "Seats"}</p>

                          
                          
                        </div>
                      

                      </div>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => Stripe(e, getStriprId_ofPlan(paymentData?.pricing, 1), 1)}
                        > Update to Monthly</button>
                      </div>
                    </div> : <div className="no-plan">
                      <img src="assets/img/no-plan.png" />
                      No Plan
                    </div>}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="payment-structure py-3">
                <div className="row">
                  {(paymentData?.pricing?.[0]?.interval_count == 3 || paymentData?.pricing?.[1]?.interval_count == 3) ? <div className="col-md-9 mx-auto">
                    <div className="price-plans">
                      <h4 className="plan-title">{paymentData?.name}</h4>
                      <div className="package-price align-items-center">
                        <h2>
                          ${num * getamountofPlan(paymentData?.pricing, 3)}
                        </h2>
                        <span>/Quaterly</span>
                      </div>

                      <div className="range-btns  py-2">
                        <div class="quantity">
                          <button
                            class="minus"
                            aria-label="Decrease"
                            onClick={decNum}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            class="input-box"
                            // value="1"
                            value={num}
                            min="1"
                            max="10"
                            onChange={handleChange}
                          />
                          <button
                            class="plus"
                            aria-label="Increase"
                            onClick={incNum}
                          >
                            +
                          </button>
                        </div>
                        <div className="users-text">
                          <p>Users</p>
                        </div>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => Stripe(e, getStriprId_ofPlan(paymentData?.pricing, 3), 3)}
                        > Update to Quaterly</button>
                      </div>
                    </div>
                  </div> : <div className="no-plan">
                    <img src="assets/img/no-plan.png" />
                    No Plan
                  </div>}
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="payment-structure py-3">
                <div className="row">
                  {(paymentData?.pricing?.[0]?.interval_count == 12 || paymentData?.pricing?.[1]?.interval_count == 12 || paymentData?.pricing?.[2]?.interval_count == 12) ? <div className="col-md-9 mx-auto">
                    <div className="price-plans">
                      <h4 className="plan-title">{paymentData?.name}</h4>
                      <div className="package-price align-items-center">
                        <h2>
                          ${num * getamountofPlan(paymentData?.pricing, 12)}
                        </h2>
                        <span>/Yearly</span>
                      </div>
                      {/* <p className=" email-text text-center">1 sendind mail</p> */}
                      <div className="range-btns  py-2">
                        <div class="quantity">
                          <button
                            class="minus"
                            aria-label="Decrease"
                            onClick={decNum}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            class="input-box"
                            // value="1"
                            value={num}
                            min="1"
                            max="10"
                            onChange={handleChange}
                          />
                          <button
                            class="plus"
                            aria-label="Increase"
                            onClick={incNum}
                          >
                            +
                          </button>
                        </div>
                        <div className="users-text">
                          <p>Users</p>
                        </div>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={(e) =>
                            Stripe(
                              e,
                              getStriprId_ofPlan(paymentData?.pricing, 12),
                              12
                            )
                          }
                        >
                          Update to Yearly
                        </button>
                      </div>
                    </div>
                  </div> : <div className="no-plan">
                    <img src="assets/img/no-plan.png" />
                    No Plan
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <ToastContainer position="top-right" />
    </>
  );
};

export default PaymentModal;
