import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ManagerDetail = () => {
  const { id } = useParams();
  const [detail, setdetail] = useState();
  const [teams, setteams] = useState();
  const [form, setform] = useState({ edit: false });
  const navigator = useHistory();

  useEffect(() => {
    if (id) {
      getDetail();
    }
    getTeams();
  }, []);

  const getTeams = () => {
    ApiClient.get(`users/list?status=active&role=team&addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        setteams(
          res?.data?.map((item) => {
            return { value: item?.id, label: item?.fullName };
          })
        );
      }
    });
  };

  const getDetail = () => {
    loader(true);
    ApiClient.get(`user/details?id=${id}`).then((res) => {
      if (res.success) {
        setdetail(res?.data);
        setform({
          fullName: res?.data?.fullName,
          email: res?.data?.email,
          teamId: {
            value: res?.data?.teamId?.id,
            label: res?.data?.teamId?.fullName,
          },
          image: res?.data?.image,
        });
      }
      loader(false);
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let payload = {
      id: detail?.id,
      fullName: form?.fullName,
      email: form?.email,
      teamId: form?.teamId?.value,
      image: form?.image,
    };
    ApiClient.put(`edit/profile`, payload).then((res) => {
      if (res.success) {
        getDetail();
        toast.success(res?.message);
        setform({ edit: false });
      }
    });
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("upload/image?modelName=users", {
      file: file,
      modelName: "users",
    }).then((res) => {
      if (res.success) {
        let image = res.data.fullpath;
        setform({ ...form, image: image });
      } else {
        setform({ ...form, image: "" });
      }
      loader(false);
    });
  };

  // const handleDelete = (id) => {
  //     if (window.confirm('Do you really want to delete this manager')) {
  //         ApiClient.delete(`delete?id=${id}&model=users`).then(res => {
  //             if (res.success) {
  //                 setTimeout(() => { toast.success(res.message) }, 100);
  //                 navigator.push(`/managers`)
  //             }
  //         })
  //     }
  // }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you really want to delete this manager ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete(`delete?id=${id}&model=users`).then((res) => {
          if (res.success) {
            setTimeout(() => {
              toast.success(res.message);
            }, 100);
            navigator.push(`/managers`);
          }
          loader(true);
        });
      }
    });
  };

  const handleStatus = (id) => {
    let status = detail?.status == "active" ? "deactive" : "active";
    if (window.confirm("Do you really want to change status of this manager")) {
      ApiClient.put(`change/status?model=users&id=${id}&status=${status}`).then(
        (res) => {
          if (res.success) {
            toast.success(res?.message);
            getDetail();
          }
        }
      );
    }
  };

  const handleCancel = () => {
    setform({ edit: false });
    getDetail();
  };

  return (
    <>
      <Layout>
        <div className="inners_wrapper main-view-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <form onSubmit={handleUpdate} className="border">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-between head-view">
                        <div className="d-flex align-items-center">
                          <label className="position-relative labeledit edit-profile-view mb-0">
                            <img
                              src={methodModel.userImg(form?.image)}
                              width="80px"
                              className={form?.edit ? "pointer" : ""}
                            />
                            {form?.edit ? (
                              <>
                                <input
                                  id="bannerImage"
                                  type="file"
                                  className="d-none"
                                  accept="image/*"
                                  value={form.baseImg ? form.baseImg : ""}
                                  onChange={(e) => {
                                    uploadImage(e);
                                  }}
                                />
                                <Tooltip placement="top" title="Upload Image">
                                  <i className="fa fa-pen pointer"></i>
                                </Tooltip>
                              </>
                            ) : null}
                          </label>
                          <span className="ml-3">
                            <div className="d-flex  justify-content-between">
                              {!form?.edit ? (
                                <>
                                  <h3 className="team-head">
                                    {detail?.fullName}
                                  </h3>
                                </>
                              ) : (
                                <div className="input-field-new">

                                <input
                                  type="text"
                                  className="form-control  field-change"
                                  value={form?.fullName}
                                  onChange={(e) =>
                                    setform({
                                      ...form,
                                      fullName: e.target.value,
                                    })
                                  }
                                  required
                                />
                                </div>
                              )}
                            </div>
                            <p className="creation-date">
                              Created on{" "}
                              {moment(detail?.createdAt).format(
                                "MMMM DD, YYYY"
                              )}
                            </p>
                          </span>
                        </div>
                        <div className="dropdowns_custom">
                          <div className="dropdown">
                            <span
                              className=" text-dark p-2"
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h text-dark"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <div className="dropdown-menu">
                              <span
                                className=" dropdown-item pointer"
                                onClick={(e) =>
                                  setform({ ...form, edit: true })
                                }
                                
                              > <i className="fa fa-pen me-2"></i> Edit</span>
                              <span
                                className="dropdown-item pointer"
                                onClick={(e) => handleStatus(detail?.id)}
                              >
                                 <i
                                  class="fa fa-users me-2"
                                  aria-hidden="true"
                                ></i>
                                {detail?.status == "active"
                                  ? "Deactivate"
                                  : "Activate"}{" "}
                                Manager
                              </span>
                              <span
                                className="dropdown-item text-danger pointer"
                                onClick={(e) => handleDelete(detail?.id)}
                              > <i className="fa fa-trash me-2"></i>
                                Delete Manager
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row description-detail">
                    <div className="col-md-3 my-2">
                      <label>Email</label>
                    </div>
                    <div className="col-md-9 my-2">
                      {!form?.edit ? (
                        <p className="mb-0">{detail?.email}</p>
                      ) : (
                        <input
                          type="email"
                          value={form?.email}
                          onChange={(e) =>
                            setform({ ...form, email: e.target.value })
                          }
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      )}
                    </div>
                    <div className="col-md-3 my-2">
                      <label>Team</label>
                    </div>
                    <div className="col-md-9 my-2">
                      {!form?.edit ? (
                        <p className="mb-0">{detail?.teamId?.fullName}</p>
                      ) : (
                        <div className="select-new">
                          <div className="select-new-inner">
                          <Select
                          value={form?.teamId}
                          isClearable={true}
                          onChange={(e) => setform({ ...form, teamId: e })}
                          options={teams}
                          placeholder="Select Team"
                          required
                        />
                          </div>
                          </div>
                      )}
                    </div>
                    {!form?.edit ? (
                      <>
                        <div className="col-md-3 my-2">
                          <label>Status</label>
                        </div>
                        <div className="col-md-9 my-2">
                          <p className="text-capitalize mb-0">
                            {detail?.status}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {form?.edit ? (
                      <div className="col-md-12 d-flex justify-content-end mt-4">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={(e) => handleCancel()}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary mx-3">
                          Update
                        </button>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ManagerDetail;
