import React, { useState } from 'react'

import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ApiClient from '../../methods/api/apiClient';
import { useDispatch } from 'react-redux';
import loader from '../../methods/loader';

const SetPassword = () => {
  const params=new URLSearchParams(window.location.search)
  const email=(params.get("email"))
  const id=(params.get("id"))
  const history = useHistory();
  const [data, setdata] = useState();
  const [form, setform] = useState({ newPassword: '', confirmPassword: '' });
  const [showpassword, setshowpassword] = useState({ new: false, confirm: false });
  const [Err, setErr] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()
    if(form?.confirmPassword!=form?.newPassword){
      setErr(true)
      return
    }
    setErr(false)
    let payload = {
      ...form,id:id
    }
    loader(true)
    ApiClient.put(`set/password?id=${id}&newPassword=${form?.newPassword}&confirmPassword=${form?.confirmPassword}`).then(res => {
      if (res?.success) {
        history.push("/login")
      }
      loader(false)
    })
  }
  return (
    <div className="signIn-main">
      <div className="container">
        <div className="row ">
          <div className="col-md-6 p-0 mx-auto  ">
            <div className="right_side">
              <form className="centerLogin" >
                <div
                  className="logo_set mb-4"

                >
                  <img
                    className="logo_login"
                    src="/assets/img/images/footer_logo.png"
                  />
                </div>

                <div className="text-center mb-4 set-password">
                  <h3 className="text-center lgtext">Set Password</h3>
                  <p className='light-text'>Set a new Paswword for
                    <span className='d-block'>{email}</span>
                  </p>
                </div>
                <form onSubmit={e => handleSubmit(e)}>
                  <div className="mb-3">
                    <label>New Password*</label>
                    <div className="inputWrapper">

                      <input
                        type={showpassword?.new ? "text" : "password"}
                        className="form-control mb-0 bginput"
                        placeholder="Atleast 8 Characters"
                        value={form?.newPassword}
                        minLength={8}
                        onChange={e=>setform({...form,newPassword:e.target.value})}
                        required
                      />
                      {showpassword?.new ? <i className="fa fa-eye" onClick={e => setshowpassword(prev => ({ ...prev, new: false }))}></i> :
                        <i className="fa fa-eye-slash" onClick={e => setshowpassword(prev => ({ ...prev, new: true }))}></i>}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label>Confirm Password<span className='red'>*</span></label>
                    <div className="inputWrapper">

                      <input
                        type={showpassword?.confirm ? "text" : "password"}
                        className="form-control mb-0 bginput"
                        placeholder="Atleast 8 Characters"
                        value={form?.confirmPassword}
                        minLength={8}
                        onChange={e=>setform({...form,confirmPassword:e.target.value})}
                        required
                      />
                      {showpassword?.confirm ? <i className="fa fa-eye" onClick={e => setshowpassword(prev => ({ ...prev, confirm: false }))}></i> :
                        <i className="fa fa-eye-slash" onClick={e => setshowpassword(prev => ({ ...prev, confirm: true }))}></i>}
                    </div>
                  </div>
             {Err && <div className='mt-0 pl-3'><small className='red'>New password and Confirm password did not match</small></div>}
                  <div className="mt-0">
                    <button
                      type="submit"
                      className="btn btn-primary loginclass mt-2"
                    >
                      Save Password
                    </button>
                  </div>
                </form>

                {/* <div className="borderCls mt-5">
              <span className="or">or</span>
            </div>
            <div className="text-center d-flex justify-content-between mt-5">
              <a className="btn btn-outline google_id">
                <img src="/assets/img/gogle.png" />
                <span className="google_heading">Google</span>
              </a>
              <a className="btn btn-outline google_id">
                <img src="/assets/img/facebooklogo.png" />
                <span className="google_heading">Facebook</span>
              </a>
            </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetPassword
