import { useEffect, useState } from "react"
import ApiClient from "../../methods/api/apiClient"
import loader from "../../methods/loader"
import PageLayout from "../../components/global/PageLayout"

export default function Privacy(){
    const [detail,setDetail]=useState()
    
    const getDetail=()=>{
        loader(true)
        ApiClient.get('content',{slug:'privacy-policies'}).then(res=>{
            if(res.success){
                setDetail(res.data)
            }
            loader(false)
        })
    }
    useEffect(()=>{
        getDetail()
    },[])
    return <>
    <PageLayout>
    <div className="container">
        <h1 className="mb-3">{detail?.title}</h1>

        <div dangerouslySetInnerHTML={{__html:detail?.description}}></div>

    </div>
    </PageLayout>
    </>
}