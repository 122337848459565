import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { RiBillLine } from "react-icons/ri";
import { SlLocationPin } from "react-icons/sl";
import { RiFileHistoryLine } from "react-icons/ri";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
// import Account-Domain Form ""
import methodModel from "../../methods/methods";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { move } from "formik";
import { useDispatch } from "react-redux";
import { FormGroup } from "react-bootstrap";

const AccountDomain = () => {
  const Navigate = useHistory();

  const user = useSelector((state) => state.user);
  const [data, setData] = useState();
  const [tab, settab] = useState([]);
  // const [details,setdetails] = useEffect([]);
  const dispatch = useDispatch();
  const navigate = useState();
console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",data)
  // const table = () => {
  //   ApiClient.get(`users/list`).then((res) => {
  //     if (res?.data) {
  //       // setData(res?.data)
  //       settab(res?.data);
  //       // setdetails(res?.data)
  //     }
  //   });
  // };

  // const move = () => {
  //   Navigate.push("/plan");
  // };
  const getTotalUser = (p = {}) => {
    loader(true);
    ApiClient.get(`profile?id=${user?.id}`).then((res) => {
      if (res.success) {
        setData(res?.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    // getTotalUser();
    // table();
    getTotalUser();
  }, []);

  const edit = () => {
    ApiClient.get(`users/list`).then((res) => {});

    // ApiClient.getPut(`edit/profile?id=`)
  };

  return (
    <>
      <Layout>
        <div class="container">
          <div class="row mb-4 align-items-center">
            <div className="col-md-6">
              <div class=" text-left">Account Name</div>
              <p className="small-text mt-1">The name of your organization.</p>
            </div>

            <div className="col-md-6">
              <div class=" text-right company-name">
                <h5>
                  {data && typeof data.fullName === "string" && (
                    <div>{data.fullName}</div>
                  )}
                </h5>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-6">
              <div class=" text-left mt-2">Company</div>
              <p className="small-text mt-1">
                This is the address where you can access your Results account.
              </p>
            </div>
            <div class="col-md-6">
              <div class=" text-right company-name">
                {data && typeof data.companyName === "string" && (
                  <a
                    // href="http://portal.jcsoftwaresolution.in:8062/home"
                    // href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* portal.jcsoftwaresolution.in:8062 */}
                    {data.companyName}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div
                    class="tab-pane fade show active"
                    id="pills-objective"
                    role="tabpanel"
                    aria-labelledby="pills-objective-tab"
                  >
                    <div className="mvp_table">
                      <div className=" table-responsive">
                        <table className="table table-hover mt-3">
                          <thead>
                            <tr>
                              <th scope="col">Account name</th>
                              <th scope="col">Plan name</th>
                              <th scope="col">Valid Upto</th>
                              <th scope="col">Number of days in the plan</th>
                            </tr>

                          </thead>
                          {
                            <tbody>
                              
                                  
                                    <tr>
                                    
                                     <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        {data?.firstName} 
                                      </td>
                                      <td>
                                      {data?.planData?.name} 
                                      </td>

                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        {data?.planValidUpTo ? 
    (() => {
      const dateObj = new Date(data.planValidUpTo);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // Months are zero-indexed, so add 1
      const date = dateObj.getDate();
      const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${date < 10 ? '0' : ''}${date}`;
      return formattedDate;
    })() 
    : "--"}
                                      </td>   
                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        {data?.createdAt && data?.planValidUpTo ? 
    (() => {
      const createdAt = new Date(data.createdAt);
      const planValidUpTo = new Date(data.planValidUpTo);
      const differenceInMs = planValidUpTo - createdAt;
      const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
      return differenceInDays;
    })() 
    : "--"}
                                      </td>                               
                                    </tr>
                                                                 
                            </tbody>
                          }
                        
                        </table>
                      </div>
                    </div>
                  </div> */}
      </Layout>
    </>
  );
};

export default AccountDomain;
