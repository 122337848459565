import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Modal, ToastContainer } from "react-bootstrap";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import ApiClient from "../../methods/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import Swal from "sweetalert2";
import { login_success } from "../../actions/user";
import { Redirect, useHistory } from "react-router-dom";
  
import { LuCalendar } from "react-icons/lu";
import Multiselect from "multiselect-react-dropdown";


const Invitemodal = ({
  show,
  handleClose,
  handleShow,
  paymentData,
  getData,
  pricing,
}) => {
 
  const user = useSelector((state) => state.user);
  const [form, setForm] = useState({});
  const [cardList, setCardList] = useState([]);
  const [numOfSeates, setNumOfSeates] = useState(1);
  const [submit, setSubmit] = useState(false);
 
  
  const [selectCard, setSelectCard] = useState({});
  const [isCardSelect, setIsCardSelect] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [num, setNum]= useState(user?.usedSeats || 1);
  const [filter, setfilters] = useState({
    page: 1,
    count: 5,
    isDeleted: false,
    // user_id: user.id,
    sortBy: "",
  });
  const history = useHistory();

  useEffect(() => {
    if (paymentData?.plan_type == "free") {
      setNumOfSeates(paymentData?.numberOfPeople);
    }
  
  }, []);




  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault(e);
    setSubmit(true);

    if (
      !dateType("card_number", form?.card_number) ||
      !dateType("cvc", form?.cvc) ||
      !dateType("month", form?.exp_month) ||
      !dateType("year", form?.exp_year)
    )
      return;

    let value = {
      ...form,
    };
    loader(true);
    ApiClient.post("card", value).then((res) => {
      if (res.success) {
        // toast.success('Add Card Successfully')
        getAllCard();
        setForm({ card_number: "", cvc: "", exp_month: "", exp_year: "" });
        setSubmit(false);

        let value = {
          planId: paymentData?._id || paymentData?.id,
          planType: paymentData?.plan_type,
          planInterval: pricing?.interval_count,
          stripe_price_id:
            paymentData?.plan_type == "free"
              ? paymentData.stripe_price_id
              : pricing?.stripe_price_id,
          paidAmount:
            paymentData?.plan_type == "free"
              ? 0
              : pricing?.unit_amount * Number(numOfSeates),
          cardId: res?.data?.cardId,
          seats: Number(numOfSeates),
          freePlanBuy: paymentData?.plan_type == "free" ? true : false,
        };
        ApiClient.post("subscription", value).then((res) => {
          if (res.success) {
            setTimeout(() => {
              toast.success(res?.message);
            }, 100);
            handleClose();
            getAllCard();
            gallaryData();
            history.push("/teams");
          }
          loader(false);
        });
      }
    });
  };

  const dateType = (type, data) => {
    let isTrue = false;

    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();

    let isYear = currentYear <= data;

    let month = moment(currentDate).format("MM");

    let isMonth =
      form?.exp_year > currentYear
        ? data <= "12"
        : month <= data && data <= "12";

    let cvc = data?.length == 3;

    let cardNumber = data?.length == 16;

    const dateType =
      type == "year"
        ? isYear
        : type == "month"
        ? isMonth
        : type == "card_number"
        ? cardNumber
        : cvc;

    if (!data) {
      isTrue = false;
    } else if (dateType) {
      isTrue = true;
    } else {
      isTrue = false;
    }

    return isTrue;
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete("card", { card_id: id }).then((res) => {
          if (res.success) {
            toast.success(res.message);
            getAllCard();
          }
          loader(false);
        });
      }
    });
  };

  const getAllCard = () => {
    loader(true);
    ApiClient.get("card").then((res) => {
     
      if (res.success) {
        setCardList(res.data);
        res?.data?.length < 1 && setIsAddCard(true);
        if (res?.data?.length == 1) {
          setIsCardSelect(true);
          setSelectCard({ ...res.data[0] });
        }
      }
      loader(false);
    });
  };

  // useEffect(() => {
  //     getAllCard()
  // }, [])

  const SetPrimaryCard = (id) => {
    let value = {
      card_id: id,
    };

    loader(true);
    ApiClient.put("card/set-primary", value).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        getAllCard();
      }
      loader(false);
    });
  };

  const SubmitPayment = (e) => {
    e.preventDefault();
    setSubmit(true);

    let value = {
      planId: paymentData?._id || paymentData?.id,
      planType: paymentData?.plan_type,
      planInterval: pricing?.interval_count,
      stripe_price_id:
        paymentData?.plan_type == "free"
          ? paymentData.stripe_price_id
          : pricing?.stripe_price_id,
      paidAmount:
        paymentData?.plan_type == "free"
          ? 0
          : pricing?.unit_amount * Number(numOfSeates),
      cardId: selectCard?.card_id,
      seats: Number(numOfSeates),
      freePlanBuy: paymentData?.plan_type == "free" ? true : false,
    };

    loader(true);
    ApiClient.post("subscription", value).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        handleClose();
        // getAllCard()
        gallaryData();
        history.push("/teams");
      }
      loader(false);
    });
  };

  const Stripe = (e,stripId,interCount) => {
    e.preventDefault();
    let url = "payment";

    const payload = {
      plan_id: paymentData?._id || paymentData?.id,
      user_id: user?.id,
      stripe_price_id: stripId || paymentData?.pricing[0]?.stripe_price_id,
      interval_count: interCount || paymentData?.pricing[0]?.interval_count,
      seats: Number(num),
    };
   
    loader(true);
    ApiClient.post(url, payload).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res?.message);
        }, 100);
        handleClose();
        gallaryData();

        window.location.assign(res?.data?.url);

       
      }
      loader(false);
    });
  };

  const gallaryData = () => {
    loader(true);
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        let u={...user,...res.data}
        dispatch(login_success(u));
      }
      loader(false);
    });
  };

  const incNum = () => {
    if (num < 10) {
      setNum(num + 1);
    }
  };

  const decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    }
  };


  const handleChange = (e) => {
    setNum(e.target.value);
  };

  const [invitemail,setinvitemail]=useState("")
  const [InvitesEmail,setInvitesEmail]=useState([])
  const [TeamId,setTeamId]=useState([])
  const [Teamdata,setTeamdata]=useState([])

  const getTeamdata=()=>{
    loader(true)
    ApiClient.get(`teams?addedBy=${user?.id || user?._id}`).then((res) => {
      if (res.success) {
        setTeamdata(res?.data);
      }
      loader(false)
    });
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const memberChangeemail = (e) => {
    if (e.key == "Enter") {
      if (validateEmail(invitemail)) {
        let data = invitemail
        let arr = InvitesEmail || []
        if (arr.length == 0) {
          arr?.push(data)
          setinvitemail("")
        } else {
          if (arr?.length > 0) {
            let bool = arr?.includes(data)
            if (!bool) {
              arr?.push(data)
              setinvitemail("")
            } else {
              return
            }
          }
        }
        setInvitesEmail(arr)
      }
    }
  }

  const removeemail=(e)=>{
    let arr=InvitesEmail
    arr=arr.filter(itm=>itm!==e)
    setInvitesEmail([...arr])
  }

  const handleinvite=()=>{
    if(InvitesEmail?.length<=0){
      return
    }
    let payload={
      employees: [...InvitesEmail],
      team: TeamId?.length>0?TeamId[0]?._id:null
    }
    ApiClient.post(`send-invite`, { ...payload }).then(res=>{
      if(res?.success){
        toast.success(res?.message)
        setInvitesEmail([]),
        setTeamId([])
        handleClose()
      }
    })
  }

  useEffect(()=>{
    getTeamdata()
  },[])

  return (
    <>
      <Modal
        className={"modal_width payment-modal"}
        show={show}
        onHide={handleClose}
        dialogClassName="modal-30w"
      >
        <Modal.Header closeButton className="align-items-center">
          <Modal.Title>Invite Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="my-2">
                          <label>
                            Invite Members<span className="text-danger"></span>
                          </label>
                        </div>
                        <div className=" my-2">
                          <div className="position-relative">
                            <input
                              type="email"
                              value={invitemail}
                              onChange={e=>setinvitemail(e.target.value)}
                              onKeyDown={e => memberChangeemail(e)}
                              className="form-control"
                              placeholder=""
                            />
                            {/* <p className="notes">Please enter to add</p> */}
                          </div>
                          <div className="d-flex selected-teams-main">
                              {InvitesEmail?.length>0&&InvitesEmail?.map(item => {
                                return <span className="selected-team">{item}<i className="ml-2 fa fa-times   pointer" onClick={e=>removeemail(item)} ></i></span>
                              })}
                            </div>
                        </div>
                        <div className="my-2">
                          <label>
                            Select Team<span className="text-danger"></span>
                          </label>
                        </div>
                        <div className=" my-2">
                          <div className="position-relative">
                            <Multiselect avoidHighlightFirstOption={true}
                                singleSelect={true}
                                selectedValues={TeamId}
                                options={Teamdata}
                                displayValue={"fullName"}
                                onSelect={e => setTeamId(e)}
                                onRemove={e => setTeamId(e)}/>
                          </div>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={e=>handleinvite()}>Invite</button>
          
        </Modal.Body>
      </Modal>

      <ToastContainer position="top-right" />
    </>
  );
};

export default Invitemodal;
