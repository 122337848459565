import React from "react";
import './style.scss';
import Select from "react-select";

const Html = ({ options, selectedValues,handleChange,displayValue,placeholder=''}) => {
    return <>
        <div className="selectDropdown">
            {/* <Multiselect
                options={options}
                singleSelect={isSingle}
                selectedValues={selectedValues}
                onSelect={e => handleChange(e,'select')}
                onRemove={e => handleChange(e,'remove')}
                displayValue={displayValue}
                id={id}
                placeholder={placeholder || 'Select'}
            /> */}
             <Select
    defaultValue={displayValue}
    isMulti
    value={selectedValues||[]}
    options={options?.map(itm => { return { value: itm.id, label: itm[displayValue] } }) || []}
    className="basic-multi-select"
    classNamePrefix="select"
    placeholder={placeholder}
    onChange={e => handleChange(e)}
  />
        </div>

    </>
}

export default Html