import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { search_success } from "../../../actions/search";
import { login_success, logout } from "../../../actions/user";
import Html from "./Html";
import ApiClient from "../../../methods/api/apiClient";

const Header = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen1, setIsOpen1] = useState(false);
  const toggle1 = () => setIsOpen1(!isOpen1);
  const history = useHistory();
  const urlPath = window.location.pathname;
  const user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);

  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    localStorage.removeItem("sessionID");
    localStorage.removeItem("sessionNAme")
    localStorage.removeItem('CreatedAt')
    history.push("/login");
  };

  useEffect(() => {
    let UserDetail = { ...user, sidebar: isOpen };
    dispatch(login_success(UserDetail));
  }, [isOpen]);

  const getuserData = () => {
    let payload = {
      id: user?.id,
    };
    ApiClient.get(`profile`, payload).then((res) => {
      if (res?.success) {
        let u={...user,...res.data}
        dispatch(login_success(u))
      }
    });
  };



  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (searchState.data) {
      dispatch(search_success(""));
    }
    getuserData()
  }, []);

  useEffect(() => {
    setSearch(searchState.data);
  }, [searchState]);

  const [search, setSearch] = useState("");

  const searchHandle = (e) => {
    e.preventDefault();
    dispatch(search_success(search));
  };

  const searchChange = (e) => {
    setSearch(e);
    if (!e) {
      dispatch(search_success(""));
    }
  };

  const clear = () => {
    setSearch("");
    dispatch(search_success(""));
  };

  const showSearch=()=>{
    if(urlPath == '/teams'){
      return true
    }else{
      return false
    }
  }


  return (
    <Html
      isOpen={isOpen}
      toggle={toggle}
      searchHandle={searchHandle}
      search={search}
      user={user}
      searchChange={searchChange}
      isOpen1={isOpen1}
      clear={clear}
      Logout={Logout}
      urlPath={urlPath}
      showSearch={showSearch}
    />
  );
};

export default Header;
