import Layout from "../../components/global/layout";
import "./style.scss";
import ApiClient from "../../methods/api/apiClient";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loader from "../../methods/loader";
import Pagination from "react-pagination-js";
import BillingDetailModal from "../PaymentModal/BillingDetailModal";
import datepipeModel from "../../models/datepipemodel";
import Invitemodal from "../PaymentModal/InviteModal";
import axios from "axios";
import environment from "../../environment";
import Invoicemodal from "../PaymentModal/Invoicemodal";

export default function BillingDetails() {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [data, setdata] = useState([]);
  const [Total, setTotal] = useState(0);
  const [tab, settab] = useState([]);
  const [selectEdPlan, setSelectEdPlan] = useState({});
  const [pricing, setPricing] = useState({ unit_amount: "" });
  const [filter, setfilters] = useState({
    page: 1,
    count: 10,
    isDeleted: false,
    // user_id: user.id,
    sortBy: "",
  });
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [TransactionFilter, setTransactionFilter] = useState("My Transactions");
  const [plans, setPlan] = useState([]);
  const [plansData, setPlanData] = useState([]);
  const [Profiledata, setProfiledata] = useState();
  const [InvoiceShow, setInvoiceShow] = useState(false)
  const handleInviteClose = () => setShowInvite(false);
  const [totalAmount, setTotalAmount] = useState(null);

  const handleInviteShow = () => setShowInvite(true);

  const handleInvoiceClose = () => setInvoiceShow(false);
  const handleInvoiceShow = () => setInvoiceShow(true);

  useEffect(()=>{
    setProfiledata(user)
    if(!user.planId) history.push('/plan')
  },[user])

  useEffect(() => {
    table();
 
  }, [TransactionFilter]);

  const table = (p = {}) => {
    loader(true);
    let payload = { ...filter, ...p };
    if (TransactionFilter == "My Transactions") {
      payload = {
        ...payload,
        user_id: user?.id||user?._id,

      }
    } else {
      payload = {
        ...payload,
        addedBy: user?.id||user?._id
      };
    }
    ApiClient.get(`transaction/all`, payload).then((res) => {
      if (res?.data) {
        setTotal(res?.total);
        settab(res?.data);
        setdata(res?.data);
        setTotalAmount(res.totalAmount); // Update the totalAmount state
      }
      loader(false);
    });
  };
 
  const payment = () => {
    let planType = user?.planData?.plan_type;
    loader(true);
    // let url = "plan";
    let url = "plans";
    ApiClient.get(url, { user_id: user?.id, status: "active" }).then((res) => {
      if (res.success) {
        setPlan(res.data);
        // setPricing({ unit_amount: res.data?.pricing?.[0]?.unit_amount });
      }
      loader(false);
    });
  };

  const filters = (p = {}) => {
    setfilters({ ...filter, ...p });
    setdata({ ...p });
  };

  const login = () => {
    ApiClient.get(`profile`, { id: user.id }).then((res) => {
      if (res.success) {
        // setlogin(res.data.fullName);
      }
    });
  };

  useEffect(() => {
    payment();
    login();
  }, []);


  const pageChange = (e) => {
    setfilters({ ...filter, page: e });
    table({ page: e });
  };


  const exportCsvv = async (id) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` }; // Set token in headers

    try {
      const res = await axios.post(
        `${environment.api}transaction/download-pdf`,
        { id: id },
        {
          headers: headers,
          responseType: 'blob'
        }
      );

      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = window.URL.createObjectURL(blob);
      downloadAnchor.download = `Invoice.pdf`;
      downloadAnchor.click();
      setInvoiceShow(false)
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
  }


  return (
    <div>
      <Layout>
        <section className="inners_wrapper">
          <div className="wraper_div">
            <div className="inners_details">
              <div className="billing_details px-4">
                <div className="">
                  <h2 className="billing_bx">
                    Billing for
                    <button className="billing_btn">
                      <div className="btn_found">
                        <img
                          src="assets/img/bag.png"
                          alt=""
                          className="img_billing"
                        />
                        {Profiledata?.fullName}
                      </div>
                    </button>
                  </h2>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-xxl-4 col-xl-6 col-lg-6 col-md-6">
                      <div className="billing_box">
                        <h6 className="detail_next">Next Invoice Issue Date</h6>
                        <p className="detail_years">
                          {datepipeModel.date(Profiledata?.planValidUpTo)}{" "}
                        
                          <span>
                           
                            
                          </span>
                        </p>
                        <p className="details_invoices pointer mt-3" onClick={() => setInvoiceShow(true)}>
                          <i
                            className="fa fa-eye eye_icon"
                            aria-hidden="true"
                          ></i>{" "}
                          My invoices
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-12 col-sm-12 col-md-4 "> 
                                            <div className="billing_box">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="detail_next">Next Invoice Issue Date</h6>
                                                    <FiEdit3 className="card_icon pointer" />
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="card_details">
                                                        <CiCreditCard2 className="pointer card_icon" />
                                                        <span className="card-code">****4470</span>
                                                        <i className="fa fa-info-circle  card_icon" aria-hidden="true"></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                    {/* <div className="col-12 col-sm-12 col-md-4 ">
                      <div className="billing_box">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="detail_next">Send invocies to</h6>
                          <div></div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-12 col-sm-12 col-xxl-4 col-xl-6 col-lg-6 col-md-6 ">
                      <div className="billing_box">
                        <h3 className=" mb-3 d-flex align-items-center ">
                          <img
                            src="assets/img/bag.png"
                            alt=""
                            className="img_billing"
                          />
                          Kanam <span className="text-primary"></span>
                        </h3>
                        <h6 className="detail_next">
                          {" "}
                          
                          {`${Profiledata?.usedSeats || 1} / ${Profiledata?.planData?.isTrial&&Profiledata?.planData?.plan_type=='paid'&&!Profiledata?.planAmountPaid?'Unlimited':(Profiledata?.numberOfSeats || 0)}`} {" "}
                    {Profiledata?.usedSeats <=1 ? "Seat" : "Seats"} use
                        </h6>
                        <p className="detail_years">
                          <span>{Profiledata?.planData?.name}</span>
                        </p>
                        {/* <p className="detail_years">
                          <span>Standard * {tab[0]?.interval_count == 1 ? "Monthly" : tab[0]?.interval_count == 12 ? "Yearly" : "Quaterly"}</span>
                        </p> */}
                        
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <p className="monthsbx">{"Total Paid Amount:"} {" "}
                            ${totalAmount || "0"}
                            {/* {tab[0]?.interval_count == 1 ? " per Month" : tab[0]?.interval_count == 12 ? " per Year" : tab[0]?.interval_count == 3 ? " per Quaterly" : ""} */}

                          </p>
                          <button
                            onClick={() => {
                              setShow(true);
                              setSelectEdPlan(Profiledata);
                            }}
                            className="btn btn-outline-primary btn-sm p-2"
                          >
                            <i className="fa fa-plus me-2 "></i>
                            Manage Plan
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 col-sm-12 col-md-4 ">
                                            <div className="billing_box">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="detail_next">Tokens spent</h6>
                                                    <FiEdit3 className="card_icon pointer" />
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <h6 className="detail_next">Free tokens</h6>
                                                    <h6 className="detail_next">Additonal tokens</h6>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="detail_next fw-bold">0/20</h6>
                                                    <h6 className="detail_next fw-bold">0/2000 <span>i00.004</span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div> */}
                                  </div>
                                </div>



                                <h4>My Transactions</h4>





                {/* <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-7">
                    <ul
                      class="nav nav-pills okrs_tabsul employee-tabs"
                      id="pills-tab"
                      role="tablist"
                    > */}
                      {/* <li class="nav-item w-50" role="presentation">
                        <button
                          class="nav-link active"
                          id="pills-objective-tab"
                          data-toggle="pill"
                          data-target="#pills-objective"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={() =>
                            setTransactionFilter("My Transactions")
                          }
                        >
                          <GrTransaction />
                          <span className="ms-2"> My Transactions</span>
                        </button>
                      </li> */}
                      {/* {!user?.isEmployee&&<li class="nav-item w-50" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-allignment-tab"
                          data-toggle="pill"
                          data-target="#pills-allignment"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                          onClick={() =>
                            setTransactionFilter("Employee Transactions")
                          }
                        >
                          <GrTransaction />
                          {/* <span className="ms-2">Employee Transactions</span> */}
                        {/* </button> */} 
                      {/* </li>} */}
                    {/* </ul>
                  </div>
                </div> */}

                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-objective"
                    role="tabpanel"
                    aria-labelledby="pills-objective-tab"
                  >
                    <div className="mvp_table">
                      <div className=" table-responsive">
                        <table className="table table-hover mt-3">
                          <thead>
                            <tr>
                          
                              <th scope="col">Monthly Cost</th>
                              <th scope="col">Transaction Date</th>
                              <th scope="col">Seat</th>

                              <th scope="col">Paid Amount</th>
                     
                            </tr>

                          </thead>
                          {
                            <tbody>
                              {tab &&
                                tab.map((item,i) => {
                                  return (
                                    <tr key={item.id}>
                                    
                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        ${item?.amount}
                                      </td>
                                       
                                      <td>
                                        {item?.createdAt
                                          ? (() => {
                                            const createdAtDate = new Date(
                                              item.createdAt
                                            );
                                            const formattedDate =
                                              createdAtDate
                                                .toISOString()
                                                .split("T")[0];
                                            return formattedDate;
                                          })()
                                          : "--"}
                                      </td>

                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        {item.transaction_type=='decrease_seats'?'-':''}{item?.seats || "0"}
                                      </td>
                                      {/*  */}

                                      <td>
                                        <span className="text-capitalize d-flex align-items-center" />
                                        ${item.amount|| "0"}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          }
                        
                        </table>
                      </div>
                    </div>
                  </div>




                  <div
                    class="tab-pane fade"
                    id="pills-allignment"
                    role="tabpanel"
                    aria-labelledby="pills-allignment-tab"
                  >
                    <div className="mvp_table">
                      <div className=" table-responsive">
                        <table className="table table-hover mt-3">
                          <thead>
                            <tr>
                              {/* <th
                                scope="col"
                                onClick={(e) => sorting("fullName")}
                              >
                                User
                                {filter?.sortBy === "fullName asc" ? (
                                  <i
                                    className="fa fa-arrow-up pl-3"
                                    title=" Z-A "
                                    onClick={(e) => sortBy("fullName desc")}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-arrow-down pl-3"
                                    title=" A-Z "
                                    onClick={(e) => sortBy("fullName asc")}
                                  ></i>
                                )} */}
                              {/* </th> */}
                              {/* <th scope="col">Role</th>
                              {/* <th scope="col">Manager Name</th>
                      <th scope="col">Employee Name</th> */}
                              {/* <th scope="col">Status</th>  */}
                              <th scope="col">Monthly Cost</th>
                              <th scope="col">Transaction Date</th>
                              <th scope="col">Seat</th>
                              <th scope="col">Paid Amount</th>
                              {/* <th scope="col">Action</th> */}
                            </tr>

                            
                          </thead>
                          
                             {
                              <tbody>
                                {tab &&
                                  tab.map((item,i) => {
                                    return (
                                      <tr key={item.id}>
                                        {/* <td>
                                          <span className="text-capitalize d-flex align-items-center">
                                            {item?.user_id_details?.fullName ||
                                              "--"}
                                          </span>
                                        </td> */}
                                        {/* <td>
                                          <span className="text-capitalize d-flex align-items-center" />
                                          {item?.user_id_details?.role || "--"}
                                        </td> */}
  
                                        {/* <td>
                                          <span className="text-capitalize d-flex align-items-center" />
                                          {item?.user_id_details?.status ? item.user_id_details.status.charAt(0).toUpperCase() + item.user_id_details.status.slice(1) : "--"}
  
                                        </td> */}
  
                                        <td>
                                          <span className="text-capitalize d-flex align-items-center" />
                                          ${item?.perMonthCost}
                                        </td>
                                        <td>
                                          {item?.createdAt
                                            ? (() => {
                                              const createdAtDate = new Date(
                                                item.createdAt
                                              );
                                              const formattedDate =
                                                createdAtDate
                                                  .toISOString()
                                                  .split("T")[0];
                                              return formattedDate;
                                            })()
                                            : "--"}
                                        </td>
  
                                        <td>
                                          <span className="text-capitalize d-flex align-items-center" />
                                          {item.transaction_type=='decrease_seats'?'-':''} {item?.seats || "0"}
                                        </td>
                                        {/*  */}
  
                                        <td>
                                          <span className="text-capitalize d-flex align-items-center" />
                                          ${item?.amount || "0"}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {show && (
            <BillingDetailModal
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
              paymentData={plans}
              pricing={pricing}
              result={()=>{
                table()
              }
              }
            />
          )}

          {InvoiceShow && (<Invoicemodal
            show={InvoiceShow}
            handleClose={handleInvoiceClose}
            handleShow={handleInvoiceShow}
            paymentData={tab}
            filter={filter}
            exportCsvv={exportCsvv}
          />
          )

          }

          {showInvite && (
            <Invitemodal
              show={showInvite}
              handleClose={handleInviteClose}
              handleShow={handleInviteShow}
              paymentData={plans}
              pricing={pricing}
            />
          )}
        </section>

        {/* <section className='details_okrs_members'>
                    <div className='team-profile team-profile-centered sp-pt-10'>
                        <div className='temasheader'>

                            <div className="d-flex align-items-center">
                                <label className="position-relative labeledit bgdata_ofs mb-0">
                                    <img src="/assets/img/login_Img.png" className="okrs_imgs" />
                                </label>
                                <span className="ml-3">
                                    <div className="d-flex  justify-content-between">
                                        <h3 className="team-head-new">kren</h3>
                                    </div>
                                    <p className="creation-date">Created on February 14, 2024</p>
                                </span>
                            </div>


                        </div>

                        <div className='teams_data_wraps'>
                            <div className='tabs_start mt-4'>
                                <ul className="nav nav-pills okrs_tabsul mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-OKRs-tab" data-toggle="pill" data-target="#pills-OKRs" type="button" role="tab" aria-controls="pills-home" aria-selected="true">OKRs</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-details-tab" data-toggle="pill" data-target="#pills-details" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                            Details</button>
                                    </li>

                                </ul>
                                <div className="tab-content " id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-OKRs" role="tabpanel" aria-labelledby="pills-OKRs-tab">
                                        <div className='acordians_okrs_result'>
                                            <div className="accordion" id="accordionExample">
                                                <div className="">
                                                    <div className="listviews_teams" id="headingOne">
                                                        <h2 className="mb-0">
                                                            <p className="pointer" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <i className='fa fa-angle-down fs16 mr-3'></i>
                                                                    <div className='width80 d-flex align-items-center'>
                                                                        <span className='fs16'>1 </span><span className='cirls_round ml-2'>4</span>
                                                                    </div>
                                                                    <div className='right_width d-flex justify-content-end align-items-center'>
                                                                        <div className='colum_one '>
                                                                            <h6> Confidence</h6>
                                                                        </div>
                                                                        <div className='colum_two'>
                                                                            <h6> Owner</h6>
                                                                        </div>
                                                                        <div className='colum_three'>
                                                                            <h6> Progress</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </h2>
                                                    </div>

                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                        <div className="listviews_teams bggray">
                                                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                                                <div className='width80 pl-2 d-flex align-items-center'>
                                                                    <span className='mr-2'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" data-icon="goal" aria-hidden="true">  <path fill="#C6DFFE" d="M0 3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"></path>  <path fill="#0052CB" fill-rule="evenodd" d="M3 8a5 5 0 1 1 10 0A5 5 0 0 1 3 8Zm5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z" clip-rule="evenodd"></path>  <rect width="3" height="3" x="6.5" y="6.5" fill="#0052CB" rx="1.5"></rect></svg>
                                                                    </span>
                                                                    <span>ABC</span>
                                                                </div>
                                                                <div className='right_width d-flex justify-content-end align-items-center'>
                                                                    <div className='colum_one'>
                                                                        <h6> </h6>
                                                                    </div>
                                                                    <div className='colum_two'>
                                                                        <div>
                                                                            <img src="assets/img/person.jpg" className='imgperson_op' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='colum_three'>
                                                                        <div className='d-flex align-items-center  column-gap-5'>
                                                                            <div className='progresstsmall'>
                                                                                <div class="progress">
                                                                                    <div class="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h6>250%</h6>
                                                                            </div>
                                                                            <div>
                                                                                <h6>250%</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className='list-items-childs'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div className='width80 marginleftchild d-flex align-items-center mb-3'>
                                                                        <span className='mr-2'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" data-icon="goal" aria-hidden="true">  <path fill="#C6DFFE" d="M0 3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"></path>  <path fill="#0052CB" fill-rule="evenodd" d="M3 8a5 5 0 1 1 10 0A5 5 0 0 1 3 8Zm5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z" clip-rule="evenodd"></path>  <rect width="3" height="3" x="6.5" y="6.5" fill="#0052CB" rx="1.5"></rect></svg>
                                                                        </span>
                                                                        <span>ABC</span>
                                                                    </div>
                                                                    <div className='right_width d-flex justify-content-end align-items-center'>
                                                                        <div className='colum_one'>
                                                                            <h6> </h6>
                                                                        </div>
                                                                        <div className='colum_two'>
                                                                            <div>
                                                                                <img src="assets/img/person.jpg" className='imgperson_op' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='colum_three'>
                                                                            <h6> Progress</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='list-items-childs'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div className='width80 marginleftchild d-flex align-items-center mb-3'>
                                                                        <span className='mr-2'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" data-icon="goal" aria-hidden="true">  <path fill="#C6DFFE" d="M0 3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"></path>  <path fill="#0052CB" fill-rule="evenodd" d="M3 8a5 5 0 1 1 10 0A5 5 0 0 1 3 8Zm5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z" clip-rule="evenodd"></path>  <rect width="3" height="3" x="6.5" y="6.5" fill="#0052CB" rx="1.5"></rect></svg>
                                                                        </span>
                                                                        <span>ABC</span>
                                                                    </div>
                                                                    <div className='right_width d-flex justify-content-end align-items-center'>
                                                                        <div className='colum_one'>
                                                                            <span className='badges_primary'>Medium</span>
                                                                        </div>
                                                                        <div className='colum_two'>
                                                                            <div>
                                                                                <img src="assets/img/person.jpg" className='imgperson_op' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='colum_three'>
                                                                            <h6> Progress</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='list-items-childs'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div className='width80 marginleftchild d-flex align-items-center mb-3'>
                                                                        <span className='mr-2'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" data-icon="goal" aria-hidden="true">  <path fill="#C6DFFE" d="M0 3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"></path>  <path fill="#0052CB" fill-rule="evenodd" d="M3 8a5 5 0 1 1 10 0A5 5 0 0 1 3 8Zm5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z" clip-rule="evenodd"></path>  <rect width="3" height="3" x="6.5" y="6.5" fill="#0052CB" rx="1.5"></rect></svg>
                                                                        </span>
                                                                        <span>ABC</span>
                                                                    </div>
                                                                    <div className='right_width d-flex justify-content-end align-items-center'>
                                                                        <div className='colum_one'>
                                                                            <span className='badges_primary'>Medium</span>
                                                                        </div>
                                                                        <div className='colum_two'>
                                                                            <div>
                                                                                <img src="assets/img/person.jpg" className='imgperson_op' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='colum_three'>
                                                                            <div className='d-flex align-items-center  column-gap-5'>
                                                                                <div className='progresstsmall'>
                                                                                    <div class="progress">
                                                                                        <div class="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <h6>250%</h6>
                                                                                </div>
                                                                                <div>
                                                                                    <h6>250%</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='list-items-childs'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div className='width80 marginleftchild d-flex align-items-center mb-3'>
                                                                        <span className='mr-2'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" data-icon="goal" aria-hidden="true">  <path fill="#C6DFFE" d="M0 3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"></path>  <path fill="#0052CB" fill-rule="evenodd" d="M3 8a5 5 0 1 1 10 0A5 5 0 0 1 3 8Zm5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z" clip-rule="evenodd"></path>  <rect width="3" height="3" x="6.5" y="6.5" fill="#0052CB" rx="1.5"></rect></svg>
                                                                        </span>
                                                                        <span>ABC</span>
                                                                    </div>
                                                                    <div className='right_width d-flex justify-content-end align-items-center'>
                                                                        <div className='colum_one'>

                                                                            <span className='badges_primary'>Medium</span>

                                                                        </div>
                                                                        <div className='colum_two'>
                                                                            <div>
                                                                                <img src="assets/img/person.jpg" className='imgperson_op' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='colum_three'>
                                                                            <div className='d-flex align-items-center  column-gap-5'>
                                                                                <div className='progresstsmall'>
                                                                                    <div class="progress">
                                                                                        <div class="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <h6>250%</h6>
                                                                                </div>
                                                                                <div>
                                                                                    <h6>250%</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </section> */}
        {/* <Pagination
          breakLabel="..."
          nextLabel="next"
          onPageChange={pageChange}
          pageRangeDisplayed={filters?.count}
          pageCount={filters?.page}
          previousLabel="< previous"
          containerClassName={"pagination"}

          // renderOnZeroPageCount={null}
        /> */}
        {Total > filter.count && (
          <div className="paginationWrapper">
            <span>
              Show {filter.count} from {Total} records
            </span>
            <Pagination
              currentPage={filter.page}
              totalSize={Total}
              sizePerPage={filter.count}
              changeCurrentPage={pageChange}
              theme="bootstrap"
            />
          </div>
        )}
      </Layout>
    </div>
  );
}
