import React, { useEffect, useState } from 'react'
import ApiClient from '../../methods/api/apiClient'
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import methodModel from "../../methods/methods";
import { logout } from "../../actions/user";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../../components/global/Footer';
const About = () => {
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { username } = useParams();
  const [on, two] = useState(username);
  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    localStorage.removeItem("sessionID");
    localStorage.removeItem("sessionNAme")
    localStorage.removeItem('CreatedAt')
    navigate.push("/login");
  }
  const [data, setdata] = useState("")
  const [description1, setdescription1] = useState(null)
  const aboutus = () => {
    ApiClient.get(`content?slug=about-us`).then(res => {
      if (res?.success) {
        setdata(res?.data)
        setdescription1(res?.data?.description1 ? res?.data?.description1 : null)
      }
    })
  }
  useEffect(() => {
    aboutus()
  }, [])
  return (
    <>
      <section className="darkbg ">
        <nav className="navbar navbar-expand-lg navbar-light homenavs px-5">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/home">
              <img src="assets/img/logo-white.png" className="logo" alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navwhite ">
                
              {!user?.isEmployee && (
                  <li className="nav-item">
                    <Link to="/home" className={on ? "nav-link active" : "nav-link"}>
                      Product
                    </Link>
                  </li>
                )}

                {!user?.isEmployee && (
                  <li className="nav-item">
                    <Link to="/plan" className={on ? "nav-link active" : "nav-link"}>
                      Pricing
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link to="/aboutus" className={on ? "nav-link active" : "nav-link"}>
                    About Us
                  </Link>
                </li>
              </ul>
              {token ? (
                <div className="dropdown">
                  <a
                    data-toggle="dropdown"
                    className="nav-link dropdown-toggle profile-after nav-link-user text-dark d-flex align-items-center"
                  >
                    <img
                      alt="image"
                      src={methodModel.userImg(user.image)}
                      className="rounded-circle mr-1"
                      width="50px"
                    />
                    <div className="ml-1 nameFont pointer ">
                      <b className="text-light text-capitalize">
                        {user.companyName}
                      </b>
                      <p className="grayCls mb-0 text-capitalize">
                        {user.role?.name}
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right position-absolute shadow bg_hover">
                    <Link to="/profile" className="dropdown-item has-icon">
                      <i className="far fa-user" /> Profile
                    </Link>
                    <Link
                      to="/profile?tab=change-pasword"
                      className="dropdown-item has-icon"
                    >
                      <i className="fa fa-cog" aria-hidden="true"></i> Change
                      Password
                    </Link>
                    <a
                      onClick={() => Logout()}
                      className="dropdown-item has-icon"
                    >
                      <i className="fas fa-sign-out-alt" /> Logout
                    </a>
                  </div>
                </div>
              ) : (
                <form className="d-flex">
                  <button
                    className="btn-white-border me-3"
                    onClick={(e) => {
                      navigate.push(`/login`);
                    }}
                    type="button"
                  >
                    Login
                  </button>

                </form>
              )}
            </div>
          </div>
        </nav>
      </section>
      <section className="eight-sect aboutus-title">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="text-center hero-wrap-text aboutus-text">
                <h1 className="text-white titleh1">

                  About Us
                </h1>
                <p className="text-white ptext text-center " dangerouslySetInnerHTML={{ __html: data ? data?.description : "<p>Lorem ipsum dolor sit amet, adipiscing elit.Tristique nibh libero vitae a dignissim lectus euismod ante. Neque scelerisque faucibus in suscipit pretium magna. Semper enim neque et sit aliquam nunc, facilisi. Odio vitae, quisque quis ut. Ipsum consequat tristique fames tempor. Bibendum sit consectetur sodales vulputate Fermentum rutrum volutpat mauris vulputate interdum metus eu et.</p>" }}>


                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="about_us">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-end">
              <div className="aboutUs">
                <img src={description1?.image1?methodModel.noImg(description1?.image1,"images/content_management"):"assets/img/service_img.png"} alt="" className="image_one" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <div className="banner_aboutus">
                <p className="title_aboutUs">About Us.</p>
                <h4 className="title_services">
                  We serve financial advisors with quality services
                </h4>
                {/* <p className="ParagrpH mb-2" dangerouslySetInnerHTML={{ __html: description1 ? description1?.content1 : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique nibh libero vitae a dignissim lectus euismod ante. Neque scelerisque faucibus in suscipit pretium magna. Semper enim neque et sit aliquam nunc, facilisi. Odio vitae, quisque quis ut. Ipsum consequat tristique fames tempor. Bibendum sit consectetur sodales scelerisque vulputate.</p>" }}> */}
{/* <p>jhgs</p> */}
                {/* </p> */}
                {/* <p className="ParagrpH mb-0">Ultrices posuere
                  neque sit pellentesque in et, non dictum. Blandit id egestas
                  diam fusce commodo. Eu massa felis, morbi vestibulum nunc.
                  Velit varius elit a, amet, tellus quam et nisl purus. Massa
                  tincidunt eget magna in nibh a. Nec vestibulum felis vitae
                  pretium. Ultricies arcu felis leo, at. Ullamcorper elementum
                  malesuada lorem{" "}</p> */}
              {/* </div>
            </div>
          </div>
        </div> */}
      {/* </section>  */}

      {/* <section className="welcome">
        <div className="container">
          <div className="row ">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="padding_welcome">
                <p className="title_aboutUs">Welcome.</p>
                <h4 className="title_welecome">
                  Hello smart people,welcome to Kanam
                </h4>
                <div className="aboutUs_welcome">
                  <img src={description1?.image2?methodModel.noImg(description1?.image2,"images/content_management"):"assets/img/welcome_img.png"} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              {/* <p className="ParagrpH mb-1" dangerouslySetInnerHTML={{ __html: description1 ? description1?.content2 : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique nibh libero vitae a dignissim lectus euismod ante. Neque scelerisque faucibus in suscipit pretium magna. Semper enim neque et sit aliquam nunc, facilisi. Odio vitae, quisque quis ut. Ipsum consequat tristique fames tempor. Bibendum sit consectetur sodales scelerisque vulputate.</p>" }}> */}
              {/* </p> */}
              {/* <p className="ParagrpH mb-1">Blandit id egestas
                diam fusce commodo. Eu massa felis, morbi vestibulum nunc. Velit
                varius elit a, amet, tellus quam et nisl purus. Massa tincidunt
                eget magna in nibh a. Nec vestibulum felis vitae pretium.
                Ultricies arcu felis leo, at. Ullamcorper elementum malesuada
                lorem a viverra dolor. Purus, non orci scelerisque feugiat eu,
                facilisi id pretium.</p>
              <p className="ParagrpH mb-0">Donec velit mauris sagittis vitae, arcu.
                Faucibus tincidunt lacus, venenatis, nisl, consequat tempor
                neque. Faucibus sed cras viverra mollis a volutpat. Neque
                hendrerit purus ut sed orci, id cursus. Varius amet eu dapibus
                lorem interdum tortor eleifend. Amet bibendum fermentum non
                dapibus augue volutpat sit turpis nulla.Nibh leo gravida posuere
                auctor in elit quisque rutrum purus. Quis sagittis id dolor
                risus cursus dignissim rhoncus. Congue ac ac facilisis etiam
                consequat lacinia. Vitae, scelerisque semper viverra consectetur
                quisque. Mi ac et orci, vitae, pellentesque faucibus consequat
                purus.</p> */}
              {/* <h3 className="welcome_names">George h - ceo of Kanam</h3> */}
            {/* </div>
          </div>
        </div> */}
      {/* </section> */} 

      {/* <section className="team_work">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="team_work_relative">
                <h4 className="title_services">our team work</h4>
                {/* <p className="ParagrpH mt-4 mb-0" dangerouslySetInnerHTML={{ __html: description1 ? description1?.content3 : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique nibh libero vitae a dignissim lectus euismod ante. Neque scelerisque faucibus in suscipit pretium magna. Semper enim neque et sit aliquam nunc, facilisi. Odio vitae, quisque quis ut. Ipsum consequat tristique fames tempor. Bibendum sit consectetur sodales scelerisque vulputate.</p>" }}> */}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Tristique nibh libero vitae a dignissim lectus euismod ante.
                  Neque scelerisque faucibus in suscipit pretium magna. Semper
                  enim neque et sit aliquam nunc, facilisi. Odio vitae, quisque
                  quis ut. Ipsum consequat tristique fames tempor. Bibendum sit
                  consectetur Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Tristique nibh libero vitae a dignissim lectus euismod
                  ante. Neque scelerisque faucibus in suscipit pretium magna.
                  Semper enim neque et sit aliquam nunc, facilisi. Odio vitae,
                  quisque quis ut. Ipsum consequat tristique fames tempor.
                  Bibendum sit consectetur */}
                {/* </p> */}
              {/* </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="team_img_bx mb-3">
                <img src={description1?.image3?methodModel.noImg(description1?.image3,"images/content_management"):"assets/img/teamwork_img.png"} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div> */}
      {/* </section>  */}

      {/* <section className="financesbx">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center space_finances flex-wrap gap-2">
            <h2 className="finances_title">
              How do we become an advisor for your finances
            </h2>
            <p className="small_paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique
              nibh libero vitae a dignissim lectus euismod ante Nequescee
              faucibus in suscipit pretium magna. Semper enim neque{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div className="orange_main">
                <div className="orange_bx">
                  <img src="assets/img/finances1.png" alt="" className="img_icons" />
                </div>
                <h5 className="icons_title">Discussion about your finances</h5>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div className="orange_main">
                <div className="orange_bx">
                  <img src="assets/img/finances2.png" alt="" className="img_icons" />
                </div>
                <h5 className="icons_title">Looking for financial problems</h5>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div className="orange_main">
                <div className="orange_bx">
                  <img src="assets/img/finances3.png" alt="" className="img_icons" />
                </div>
                <h5 className="icons_title">Discussion about your finances</h5>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <div className="orange_main">
                <div className="orange_bx">
                  <img src="assets/img/finances4.png" alt="" className="img_icons" />
                </div>
                <h5 className="icons_title">Looking for financial problems</h5>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="accomplish">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="title ">accomplish your financial goals with us</h2>
              <p className="accomplish_para ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Tristique nibh libero vitae a dignissim lectus euismod ante
                Nequescee faucibus in suscipit pretium magna. Semper enim neque{" "}
              </p>
              <button className="btn btn-primary">Let’s talk</button>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="growing_bx">
        <div className="container">
          <div className="row align-items-center flex-column-reverse flex-md-row">
            <div className=" col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h4 className="title_services">
                Has become the fastest growing company
              </h4>
              {/* <p className="ParagrpH mb-1" dangerouslySetInnerHTML={{ __html: description1 ? description1?.content4 : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique nibh libero vitae a dignissim lectus euismod ante. Neque scelerisque faucibus in suscipit pretium magna. Semper enim neque et sit aliquam nunc, facilisi. Odio vitae, quisque quis ut. Ipsum consequat tristique fames tempor. Bibendum sit consectetur sodales scelerisque vulputate.</p>" }}>

              </p> */}
              {/* <p className="ParagrpH mb-0">Blandit id egestas
                diam fusce commodo. Eu massa felis, morbi vestibulum nunc. Velit
                varius elit a, amet, tellus quam et nisl purus. Massa tincidunt
                eget magna in nibh a. Nec vestibulum felis vitae pretium.
                Ultricies arcu felis leo, at. Ullamcorper elementum malesuada
                lorem a viverra dolor. Purus, non orci scelerisque feugiat eu,
                facilisi id pretium. Donec velit mauris sagittis vitae, arcu.
                Faucibus tincidunt lacus, venenatis, nisl, consequat tempor
                neque. Faucibus sed cras viverra mollis a volutpat.</p> */}
            {/* </div>
            <div className=" col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="growing_img">
                <img src={description1?.image4?methodModel.noImg(description1?.image4,"images/content_management"):"assets/img/growing_img"} alt="" className="image_two" />
              </div>
            </div>
          </div>
        </div>
      </section> */} 
       <Footer/>
    </>
  );
};

export default About;
